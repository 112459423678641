import { BrowserRouter as Router, Switch, Route, withRouter,Redirect } from "react-router-dom";
import api from "./connection/api.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

//redux
import { Provider } from 'react-redux';
import store from './store';

import Components from "./components";

//Componets
import Header from './componets/Header';
import AppBoard from './pages/AppBoard';
import TileBoard from './pages/TileBoard';
import { useState, useEffect } from "react";
import Spinner from "./componets/Spinner";

import Login from "./pages/auth/Login";
import PasswordReset from "./pages/auth/PasswordReset";
import { Signup } from "./pages/auth/Signup";

import PrivateRoute from "./routing/PrivateRoute";
import { ThankYou } from "./pages/Subscription/ThankYou";

import Landing2 from "./pages/Landings/Landing2";
import EmailLink from "./pages/auth/EmailLink";
import Template from "./pages/Templates/Template.js";
import Blocked from "./pages/Blocked.js";

function App(props) {
  const [apps, setApps] = useState([])
  const [tiles, setTiles] = useState([])
  const [children, setchildren] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [isBilling, setIsBilling] = useState([]);
  const [companySubscription, setCompanySubscription] = useState([]);
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
  
      setIsLoading(true);
      getApps();
      getTiles();
      getChildren();
      checkIsBilling();
      getCompanySubscription();
      getCompany();
  
  },[])

  const checkIsBilling = (error) => {
        
    fetch(api.url() + '/company/checkBilling', {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    }).then(response => response.json())
        .then(data => {
            setIsBilling(data)
            
        })
        .catch(error => {
           
        });
}

const getCompanySubscription = async () => {
  
  await fetch(api.url() + '/subscription/getCustomerSubscription', {
     method: 'GET',
     headers: {
       'Authorization': 'Bearer ' + localStorage.getItem('token')
     }
   }).then(response => response.json())
     .then(data => {
      setCompanySubscription(data);
    
     })
     .catch(error => {
       // handleError(error);
     });
    
 }

  const getApps = async () => {
 
    fetch(api.url() + '/app/getallapps', {
      method: 'GET',
     
    })
      .then(response => {

        return response.json()
      })
      .then(data => {
        setApps(data);
      }).catch(error => {
       
      });
  }

  const getTiles = async () => {
    
    fetch(api.url() + '/tiles', {
      method: 'GET',
    
    })
      .then(response => {
       
        return response.json()
      })
      .then(data => {
        setTiles(data);
      }).catch(error => {
       
      });
  }

  const getChildren = async () => {
    fetch(api.url() + '/children')
      .then(response => {
        return response.json()
      })
      .then(data => {
        setchildren(data);
        setIsLoading(false);
      }).catch(error => {

      });
  }
  const getCompany = async () => {
    await fetch(api.url() + '/company', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(company => {
       if(company.isBlocked==1)
       {
          setIsBlocked(true)
       }
     
      })
      .catch(error => {
        // handleError(error);
      });
     
  }

  const NotFoundPage = () => {
    return (
      <div>Missing Page</div>
    )
  }

  return (
    <Provider store={store}>
      {
        isLoading ? <Spinner />
          :
          <Router>
            
            <div id="mainContainer" className={  
              props.location.pathname !== '/' ? 'mainContainer' : ''
            }>
              
               {isBlocked && props.location.pathname !== '/login' ? <Redirect from="/app" to="/blocked" /> : !isBilling && props.location.pathname !== '/login' && !companySubscription.productId && <Redirect from="/app" to="/app/manage/billing" />}
              
              <Switch>
                <Route exact path="/404" component={NotFoundPage} />
                <Route exact path="/" component={Landing2} />
                <Route exact path="/signup" component={Signup} />
                <Route path="/thankyou" component={ThankYou} />
                <Route path="/emaillink/:link" component={EmailLink} />
                <PrivateRoute exact path="/app" component={AppBoard} />
                <PrivateRoute exact path="/app/:appname" component={TileBoard} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/blocked" component={Blocked} />
                <Route exact path="/reset/:key" component={PasswordReset} />
                <Route exact path="/reset/" component={PasswordReset} />
                {!apps &&
                  <PrivateRoute path="/" component={Login} />
                }

                {apps && apps.map(n => (
                  tiles && tiles.map(tile => (
                  
                  
                    <PrivateRoute key={n.id } exact path={'/app/' + n.Link.toLowerCase() + '/' + tile.Link.toLowerCase()} component={Components(tile)}>
                    </PrivateRoute>
                  ))
                ))}

                {apps && apps.map(n => (
                  tiles && tiles.map(tile => (
                    children && children.map(child => (  
                      <PrivateRoute key={n.id} exact path={'/app/' + n.Link.toLowerCase() + '/' + tile.Link.toLowerCase() + '/' + child.link.toLowerCase()} component={Components(child)}>
                      </PrivateRoute>
                    ))
                  ))
                ))}

                {/* <Redirect to="/404" /> */}
              </Switch>
            </div>
          </Router>
      }
    </Provider>
  );
}

export default withRouter(App);
