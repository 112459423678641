import React from 'react'

function Missing() {
    return (
        <div>
            Looks like this page is missing from the universe.
        </div>
    )
}

export default Missing;
