import { useState, useEffect } from 'react';
import React from 'react'
import PropTypes from 'prop-types'

import { Typeahead } from 'react-bootstrap-typeahead';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import { FilterVintageRounded } from '@material-ui/icons';

const Employees = ({ employees, selected, name, title, onChange, sortable }) => {
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [employee, setEmployees] = useState([]);

    useEffect(() => {
        if (selected) {
            setEmployees(selected);
        }
    }, [selected, employees])

    const changeEmployees = (newEmployees) => {
        setEmployees([...newEmployees]);
        if (onChange) onChange({
            target: {
                name,
                value: newEmployees
            }
        })
    }
    const addEmployee = (event) => {
        changeEmployees([...employee, ...event]);
        setSelectedEmployee([]);
    }

    const deleteEmployee = (employeeId) => {
        changeEmployees(employee.filter(employee => employee.employeeId !== employeeId));
    }


    function array_move(arr, old_index, new_index) {
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };

    const moveUp = (employeeId) => {
        const oldPosition = employee.findIndex(employee => employee.employeeId === employeeId);
        const newPostion = oldPosition - 1;
        if (newPostion < 0) return;
        changeEmployees(array_move([...employee], oldPosition, newPostion));
    }

    const moveDown = (employeeId) => {
        const oldPosition = employee.findIndex(employee => employee.employeeId === employeeId);
        const newPostion = oldPosition + 1;
        if (newPostion === employee.length) return;
        changeEmployees(array_move([...employee], oldPosition, newPostion));
    }

    const filterByCallback = (option, props) => {


        return (employee.findIndex(employee => employee.employeeId == option.employeeId) === -1 &&
            option.firstName.toLowerCase().indexOf(props.text.toLowerCase()) !== -1)
    }

    return (
        <>
            <Row>
                <div className="col-12">
                    {title}
                </div>
                <div className="col-3">
                    <Typeahead
                        id="toggle-example"
                        labelKey="firstName"
                        options={employees}
                        filterBy={filterByCallback}
                        selected={selectedEmployee}
                        placeholder={name}
                        onChange={addEmployee}>

                    </Typeahead>
                </div>

            </Row>

            <Table striped bordered hover variant="light">
                <tbody>
                    {employee.map((employee, index) => (
                        <tr>
                            <td>{employee.FirstName || employee.firstName}</td>
                            <td>
                                {sortable && <>
                                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { moveUp(employee.employeeId) }} width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { moveDown(employee.employeeId) }} width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                </>
                                }
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { deleteEmployee(employee.employeeId) }} width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                </svg>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}

Employees.propTypes = {
    employees: PropTypes.array,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    sortable: PropTypes.bool
}

export default Employees
