import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';

import AppCard from '../componets/AppCard';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from '../componets/Spinner';
import api from '../connection/api'
import Payquest from '../Assets/fwdartwork/ItsAboutPayroll_Branding_Board_PayQuest_App.png';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Navbar} from 'react-bootstrap';

const TileBoard = () => {
    const { appname } = useParams();
    const [tiles, setTiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {

            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    useEffect(() => {
        getTiles();
    }, [])

  

    const getTiles = async () => {

        fetch(api.url() + '/tiles/getTilesbyroles/' + appname + '', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
              }
        }).then(response => response.json())
            .then(data => {
              
                setTiles(
                    data.sort(
                        (a, b) => {
                            if (a.Sort > b.Sort) {
                                return 1;
                            } else if (a.Sort < b.Sort) {
                                return -1;
                            }
                            return 0;
                        }
                    )
                );
                setIsLoading(false);
            })
            .catch(error => {
                handleError()
            });

    }


    const getColor = (tile) => {
        //tile.color
        // if (!Components[tile.component]) {
        //     return 'red';
        // }
        return tile.Color;
    }

    return (
        <>{
            isLoading ? <Spinner />
                :
                <Container>
                <div>
              <Navbar bg="#fff" expand="md">
                <div style={{ paddingLeft: '5px', textAlign: 'center', position: 'absolute', paddingBottom: '125px'}}>
                <img src={Payquest} alt='planetLogo' style={{width:'150px', height:'40px'}}></img>
                </div>
                 
              </Navbar>
              </div>
                    <Row>
                        {tiles.map(tile => (
                            <Col key={tile.Id} className="col-12 col-md-4 col-lg-3">
                                <Link to={'/app/' + tile.appname + '/' + tile.Link.toLowerCase()}>
                                    <AppCard title={tile.Name} color={getColor(tile)}></AppCard>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </Container>
        }
        </>
    )
}

export default TileBoard
