import { useState, Fragment, useEffect } from 'react';
import Spinner from '../../componets/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import RequestApproval from '../../componets/pendingRequest';
import { formatter } from '../../utils/currency';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../connection/api'
import { Modal } from 'react-bootstrap';
import {
    useQuery,
    gql,
    useLazyQuery,
    useMutation,
} from "@apollo/client";






const AllHelpRequests = ({ history, match, buttonComponent }) => {
    const [helprequests, setHelpRequests] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [state, setState] = useState({
        remark: '',
        status: '',
        employee:''

    });
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [status, setStatus] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(async () => {
        getcompany();
        getHelpRequest();
    }, [])
    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    

    const updateState = (event) => {
        const { name, value } = event.target;
        setState({
            ...state,
            [name]: value,
        })
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleViewClick = (request) => {
        setSelectedRequest(request);
        setShowModal(true);
    };

    





    const getcompany = async () => {
        setIsLoading(true)
        fetch(api.url() + '/company', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                
                setCompanies(data);


            }).catch(error => {

            });
    }


    const getHelpRequest = async () => {
        setIsLoading(true)
        fetch(api.url() + '/helprequest', {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }),
        }).then(response => response.json())
            .then(data => {
               
                setHelpRequests(data)
                setIsLoading(false)
            })
            .catch(error => {
                handleError(error);

            });


    }

    const UpdateHelpRequest = async (selectedRequest) => {
        state.status = status;
        state.helpRequestId = selectedRequest.helpRequestId;
        state.employee=selectedRequest.employee

        fetch(api.url() + '/helprequest/update', {
            method: 'PUT',
            body: JSON.stringify(state),
            headers: new Headers({
                'Content-type': 'application/json; charset=UTF-8',

            }),
        }).then(response => response.json())
            .then(data => {
                window.location.reload();
            })
            .catch(error => {
                handleError(error);

            });



    }



    const filteredRequests = helprequests.filter(request => {
        const companyFilter = selectedCompany
            ? request.employee.company.name === selectedCompany
            : true;
        const statusFilter = selectedStatus
            ? request.status === selectedStatus
            : true;
        return companyFilter && statusFilter;
    });


    return (
        <>{
            isLoading ? <Spinner />
                :
                <Container>

                    <div className="filter-container">
                        <h6>Filter By</h6>
                        <select
                            value={selectedCompany}
                            onChange={e => setSelectedCompany(e.target.value)}>
                            <option value="">All Companies</option>
                            {companies.map(company => (
                                <option key={company.id} value={company.name}>
                                    {company.name}
                                </option>
                            ))}
                        </select>


                        <select style={{ marginLeft: '15px' }}
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}
                        >
                            <option value="">All Status</option>
                            <option value="PENDING">Pending</option>
                            <option value="REQUIRE INPUT">Require Input</option>
                            <option value="IN PROGRESS">In Progress</option>
                            <option value="COMPLETED" >Completed</option>
                        </select>
                    </div>
                    <Row>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Requester</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">SubCategory</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                {filteredRequests && filteredRequests.map((request, index, lineitem,) => (
                                    <Fragment key={request.helpRequestId}>
                                        <tr key={request.helpRequestId}>
                                            <td>{request.employee.firstName}</td>
                                            <td>{request.company.name}</td>
                                            <td>
                                                {request.category}
                                            </td>
                                            <td>{request.subCategory}</td>
                                            <td>{request.date}</td>
                                            <td>{request.status}</td>
                                            <td>
                                                <button onClick={() => handleViewClick(request)} type="button" className="btn btn-primary">view</button>
                                            </td>
                                        </tr>

                                    </Fragment>
                                ))}
                            </tbody>
                        </table>
                        <Modal show={showModal} onHide={handleCloseModal} size='lg'>
                            <Modal.Header >
                                <Modal.Title>Help Request Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {selectedRequest && (
                                    <div>
                                        <div style={{ textAlign: 'center' }}>
                                            <img
                                                src={`data:image/jpeg;base64,${selectedRequest.attachment}`}
                                                style={{ maxWidth: '100%', borderRadius: '20px' }}
                                                alt="Attachment"
                                            />
                                        </div>
                                        <div style={{ marginTop: '20px' }}>
                                            <h5>Description</h5>
                                            <p>{selectedRequest.description}</p>
                                        </div>
                                        {selectedRequest.status != "COMPLETED" &&
                                            <div style={{ marginTop: '20px' }}>
                                                <h5>Remark</h5>
                                                <InputGroup className="mb-3">
                                                    <FormControl as="textarea" name="remark" placeholder="Remark" aria-label="remark" value={state.remark} onChange={updateState} aria-describedby="inputGroup-sizing-sm" style={{ height: "120px" }} />
                                                </InputGroup>

                                            </div>
                                        }

                                        {selectedRequest.status != "COMPLETED" &&
                                            <div style={{ marginTop: '20px' }}>
                                                <h5>Status</h5>
                                                <select style={{ width: '10vw' }}
                                                    value={status} onChange={e => setStatus(e.target.value)}>
                                                    <option value={selectedRequest.status}>{selectedRequest.status}</option>
                                                    <option value="IN PROGRESS">In Progress</option>
                                                    <option value="REQUIRE INPUT">Require Input</option>
                                                    <option value="COMPLETED">Completed</option>

                                                </select>
                                            </div>
                                        }
                                        {selectedRequest.status == "COMPLETED" &&
                                            <div style={{ marginTop: '20px' }}>
                                                <h5>Status</h5>
                                                <p>{selectedRequest.status}</p>
                                            </div>
                                        }
                                         {selectedRequest.remark != "" &&
                                        <div style={{ marginTop: '20px' }}>
                                            <h5>Remark</h5>
                                            <p>{selectedRequest.remark}</p>
                                        </div>
                                        }

                                        <div style={{ marginTop: '20px' }}>
                                            {selectedRequest.status != "COMPLETED" &&
                                                <button className="btn btn-success" onClick={() => UpdateHelpRequest(selectedRequest)}>
                                                    Submit
                                                </button>
                                            }


                                        </div>
                                    </div>

                                )}
                            </Modal.Body>
                            <Modal.Footer>

                                <button className="btn btn-secondary" onClick={handleCloseModal}>
                                    Close
                                </button>
                            </Modal.Footer>
                        </Modal>


                    </Row>

                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </Container >
        }
        </>
    )
}

export default AllHelpRequests;