import Bonuslist from './pages/Bonuslist';
import Groups from './pages/Groups';
import Employees from './pages/Employees';
import Requests from './pages/request';
import Missing from './pages/Missing';
import Codes from './pages/Codes';
import CheckDates from './pages/Dates';
import Report from './pages/Report';
import CompanyManager from './pages/CompanyManager';
import User from './pages/User';
import { Invoice } from "./pages/Subscription/Invoice";
import { PaymentMethod } from "./pages/Subscription/PaymentMethod";
import Template from './pages/Templates/Template';
import CreateHelpRequest from './pages/Help/CreateHelpRequest';
import MyHelpRequest from './pages/Help/MyHelpRequest';
import AllHelpRequests from './pages/Help/AllHelpRequests';
import ClientManagement from './pages/ClientManagement';





export const Components = {
    Bonuslist,
    GroupList: Groups.List,
    GroupEdit: Groups.Edit,
    Missing,
    EmployeeList: Employees.List,
    EmployeeEdit: Employees.Edit,
    MassApproval: Requests.MassApproval,
    CodeList: Codes.List,
    CodeEdit: Codes.Edit,
    CheckDates: CheckDates.List,
    CheckEdit: CheckDates.Edit,
    MyRequests: Requests.MyRequests,
    AllRequests: Requests.AllRequests,
    Batches: Report.Batches,
    Manager: CompanyManager.List,
    CompanyEdit: CompanyManager.Edit,
    UserList: User.List,
    UserEdit: User.Edit,
    Invoice,
    PaymentMethod,
    MyHelpRequests:MyHelpRequest,
    CreateHelpRequest:CreateHelpRequest,
    Templates:Template,
    AllHelpRequests:AllHelpRequests,
    ClientManagement:ClientManagement
   
  
};

const componentCreator = block => {

   
    if (typeof Components[block.component] !== "undefined") {
        return Components[block.component];
    }
    else {
        return Missing;
    }
}

export default componentCreator;