import {
  ADD_APP,
  REMOVE_APP,
} from '../actions/types';

const initialState = [
  // {
  //   id: 1,
  //   name: 'Payment Request',
  //   link: 'sass',
  //   color: 'darkblue',
  //   component: 'Bonuslist',
  //   children: [],
  // },
  // {
  //   id: 2,
  //   name: 'Payroll University',
  //   link: 'sass',
  //   color: 'darkgray',
  //   children: [],
  // },
  // {
  //   id: 3,
  //   name: 'Group Manager',
  //   link: 'manager',
  //   component: 'GroupList',
  //   color: 'green',
  //   children: [
  //     {
  //       name: 'Edit',
  //       link: 'edit',
  //       component: 'GroupEdit',
  //       color: 'green',
  //     }
  //   ]
  // }
];

const appState = (state = initialState, action) => {
  switch (action.type) {
    case ADD_APP:
      return [
        ...state,
        action.payload
      ];
    case REMOVE_APP:
      return state.filter(item => item.id !== action.payload);
    default:
      return state;
  }
};

export default appState;