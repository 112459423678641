import React from 'react'
import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import api from '../../connection/api'
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../componets/Spinner';
import { Button } from 'bootstrap';

function CreateHelpRequest() {

    const history = useHistory();
    const match = useRouteMatch();

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    category: '',
    subCategory: '',
    description: '',
    remark: '',
    status: '',
    attachment: null
});

  const handleError = (error) => {
      if (error.message) {
          toast.error(error.message);
      } else if (error.networkError) {

          toast.error('A network error has occured');
          console.error('Error', error.networkError.result.errors);
      } else {
          toast.error('An unknown error has occured');
      }
  }

  const updateState = (event) => {
    const { name, value, files } = event.target;
    if (files) {
        const file = files[0];

        if (file.type.startsWith('image/')) {
            convertToBase64(file, (base64Data) => {
                setState({ ...state, [name]: base64Data });
            });
        } else {
            toast.error('Please select a valid image file');
        }
    } else {
        setState({ ...state, [name]: value });
    }
};


  const convertToBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result.split(',')[1];
      callback(base64String);
    };
    reader.readAsDataURL(file);
  };

  const returnToHelp = () => {
    let newPath = match.path.substring(0, match.path.lastIndexOf('/') + 1);
    if (newPath.includes('createhelprequest')) {
        newPath = newPath.substring(0, newPath.lastIndexOf('/'));
    }
    history.push(newPath);
}

const addHelpRequest = async () => {
    fetch(api.url() + '/helprequest/add', {
        method: 'POST',
        body: JSON.stringify(state),
        headers: new Headers({
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }),
    })
        .then(returnToHelp())
        .catch(error => {
            // Handle any errors
            console.error(error);
        });
}
const onSave = () => {

    addHelpRequest();
  
   
}

  
  
  return (
    <>
    {
            isLoading ? <Spinner />
                :
                <div style={{ width: '80%' }}>
                Create Help Request
                <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4"></div>
                <div className="col-12 col-md-4" style={{marginTop:'20px'}}>
                    <InputGroup className="mb-3">
                        <select name="category" onChange={updateState} value={state.category}  style={{width:'15vw'}}>
                            <option value="">Select Category</option>
                            <option value="Payment Request">Payment Request</option>
                            <option value="BILLING">Billing</option>
                            <option value="EMPLOYEE">Employee</option>
                        </select>    
                    </InputGroup>
                    <InputGroup className="mb-3" >
                        <select name="subCategory" onChange={updateState} value={state.subcategory} style={{width:'15vw'}}>
                            <option value="">Select Sub Category</option>
                            <option value="PAYMENT REQUEST">Payment Request Approval</option>
                            <option value="INVOICE">Invoice</option>
                            <option value="TERMINATED">Terminated</option>
                        </select>    
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <FormControl as="textarea" name="description" placeholder="Description" aria-label="description" value={state.description} onChange={updateState} aria-describedby="inputGroup-sizing-sm" style={{ height: "120px" }}/>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <FormControl type="file" name="attachment" placeholder="Attachment" aria-label="attachment" onChange={updateState} aria-describedby="inputGroup-sizing-sm"/>
                    </InputGroup>
            

                </div>
                <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4">
                </div>
                <div>
                    <button type="button" className="btn btn-secondary" >Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={onSave}>Save</button>
                </div >
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                />
            </div>
        }            
    </>
  )
}

export default CreateHelpRequest