import { useState, Fragment, useEffect } from 'react';
import Spinner from '../../componets/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import RequestApproval from '../../componets/pendingRequest';
import { formatter } from '../../utils/currency';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../connection/api'
import { Modal } from 'react-bootstrap';
import {
    useQuery,
    gql,
    useLazyQuery,
    useMutation,
} from "@apollo/client";

const CANCEL_GQL = gql`
    mutation CancelRequest($id: ID!) {
        cancelPayRequest(input: {
        id: $id
        }){
            id
            status
            total
            reason
                        checkDate {
                    id
                    date
                    formattedDate
            }
            lineitems {
                id
                amount
                reason
                status
                code {
                    id
                    label
                }
                employee {
                    id
                    name
                    number
                    email
                    title
                    status
                }
            }
        }
    }
`





const MyHelpRequest = ({ history, match, buttonComponent }) => {
    const [paydetails, setPaydetails] = useState([]);
    const [helprequests, setHelpRequests] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [date, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);

    useEffect(async () => {
        getHelpRequest();
    }, [])
    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleViewClick = (request) => {
        setSelectedRequest(request);
        setShowModal(true);
    };


    const onCancel = (id) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Are You Sure?")) {
            setIsLoading(true)
            fetch(api.url() + '/paymentrequest/updateStatus?status=CANCELED&paymentRequestId=' + id
                , {
                    method: 'PUT',

                    headers: new Headers({
                        'Content-type': 'application/json; charset=UTF-8',
                    }),
                }).then(response => response.json())
                .then(data => {
                    window.location.reload(false);
                })
                .catch(error => {
                    // Handle any errors
                    handleError(error)
                });
        }
    }


    const buttonWrapper = ({ request, index, }) => (
        request.status === 'PENDING' && <>
            <button onClick={() => onCancel(request.id, index)} type="button" className="btn btn-danger">Cancel</button>
        </>
    )

    const getHelpRequest = async () => {
        setIsLoading(true)
        fetch(api.url() + '/helprequest/getByEmployee', {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }),
        }).then(response => response.json())
            .then(data => {
               
                setHelpRequests(data)
                setIsLoading(false)
            })
            .catch(error => {
                handleError(error);

            });


    }
    const onReview = (requestId) => {

        const newRequests = [...helprequests];
        const index = newRequests.findIndex(request => request.paymentrequest.Id === requestId);
       
        const getpaydet = paydetails.filter(obj => obj.paymentRequest.Id == newRequests[index].paymentrequest.Id)

        newRequests[index] = {
            ...newRequests[index],
            open: !newRequests[index].open,
            paymentdetails: getpaydet

        };
        setHelpRequests(newRequests); 
    }
    return (
        <>{
            isLoading ? <Spinner />
                :
                <Container>
                    <Row>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Requester</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">SubCategory</th>
                                    <th scope="col">Date</th>
                                    
                            
                                </tr>
                            </thead>
                            <tbody>
                                {helprequests && helprequests.map((request, index, lineitem,) => (
                                    <Fragment key={request.helpRequestId}>
                                        <tr key={request.helpRequestId}>
                                        <td>{request.employee.firstName}</td> 
                                        <td>{request.employee.company.name}</td> 
                                            <td>
                                                {request.category}
                                            </td>
                                            <td>{request.subCategory}</td> 
                                            <td>{request.date}</td>
                                           
                                            <td>
                                            <button onClick={() => handleViewClick(request)} type="button" className="btn btn-primary">view</button>
                                            </td>
                                        </tr>
                                        
                                    </Fragment>
                                ))}
                            </tbody>
                        </table>
                        <Modal show={showModal} onHide={handleCloseModal} size='lg'>
                                <Modal.Header >
                                    <Modal.Title>Help Request Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {selectedRequest && (
                                        <div>
                                            <div style={{ textAlign: 'center' }}>
                                                <img
                                                    src={`data:image/jpeg;base64,${selectedRequest.attachment}`}
                                                    style={{ maxWidth: '100%', borderRadius: '20px' }}
                                                    alt="Attachment"
                                                />
                                            </div>
                                            <div style={{ marginTop: '20px' }}>
                                                <h5>Description</h5>
                                                <p>{selectedRequest.description}</p>
                                            </div>
                                        </div>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-secondary" onClick={handleCloseModal}>
                                        Close
                                    </button>
                                </Modal.Footer>
                            </Modal>


                    </Row>

                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </Container >
        }
        </>
    )
}

export default MyHelpRequest;