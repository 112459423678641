import React, { useState, useEffect } from 'react'
import AppCard from '../../componets/AppCard'
import { Col ,Container} from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Spinner from '../../componets/Spinner';
import api from '../../connection/api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TemplateCard from '../../componets/TemplateCard';

function Template(props) {
  
    const [Template, setTemplate] = useState([]);
    const [companytemplate, setCompanytemplate] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
     
      getTemplate();
      getCompanyTemplate();
  }, [])
  
    const getTemplate = async () => {
  
      fetch(api.url() + '/template' , {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
      }).then(response => response.json())
          .then(data => {           
              setTemplate(data)
              setIsLoading(false)
             
          })
          .catch(error => {
             
          });
  }
  const getCompanyTemplate = async () => {
  
    await fetch(api.url() + '/template/getcompanytemplate', {
       method: 'GET',
       headers: {
         'Authorization': 'Bearer ' + localStorage.getItem('token')
       }
     }).then(response => response.json())
       .then(data => {
        setCompanytemplate(data)
        
       })
       .catch(error => {
         // handleError(error);
       });
      
   }
  const setdefault = (templateid) => {
    setIsLoading(true)
        let companytempid;
        if(companytemplate.id == undefined)
        {
             companytempid = 0;
        }
        else{
          companytempid = companytemplate.id;
        }
      fetch(api.url() + '/template/updatetemplate?templateid='+templateid+'&id='+companytempid, {
         method: 'POST',
         headers: {
           'Authorization': 'Bearer ' + localStorage.getItem('token')
         }
       }).then(response => response.json())
         .then(data => {
          setCompanytemplate(data)
          setIsLoading(false)
         })
         .catch(error => {
           // handleError(error);
         });
        
     
  }
  
  
    
  
    return (
      <div>
          {
            isLoading ? <Spinner />
                      :
        <Container>
         <Row>
           {Template.map(temp => (
            <Col key={temp.id} className="col-12 col-md-4 col-lg-3">
           <TemplateCard template={temp} setdefault={setdefault} companytemplate={companytemplate}></TemplateCard>
           </Col>
           ))}
           </Row>
           </Container>
  }
      </div>
    )
}

export default Template