import React, { useEffect, useRef, useState } from "react";
import { Route, Redirect } from "react-router-dom";
// import setAuthToken from "../../utils/setAuthToken";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Login from "../pages/auth/Login";
import Header from "../componets/Header";

const PrivateRoute = ({ component: Component, path, customAuth, ...rest }) => {
  useEffect(() => {
    if (customAuth.isUserAuthenticated()) {
      const fn = async () => {
        if (customAuth.isUserAuthenticated()) {
          // setAuthToken(customAuth.token);
        }
      };
      fn();
    }

    // eslint-disable-next-line
    return () => { };
  }, [customAuth.isUserAuthenticated(), path, customAuth.token]);
  const render = (props) =>
    customAuth.isUserAuthenticated() || customAuth.token === true ? (
      <>
        <Header />
        <Component {...props} />
      </>
    ) : <Login></Login>;

  return <Route path={path} render={render} {...rest} />;
};

PrivateRoute.propTypes = {
  customAuth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customAuth: state.authProvider,
});

export default connect(mapStateToProps)(PrivateRoute);
