import React, { useEffect, useState } from 'react'
import { useMutation, gql, useQuery } from '@apollo/client';
import { connect } from 'react-redux'
import { loginUser } from '../../actions/authActions'
import { ToastContainer, toast } from 'react-toastify';
import { Redirect, useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const VALIDATE_LINK = gql`
    query Query($link: String!) {
        validateLink(link: $link)
    }
`;

const LOGIN_GQL = gql`
    mutation Magiclink($input: LinkInput!) {
    magiclink(input: $input) {
        token
        url
        companyid
        employee{
            company {
                id
                name
                status
            }
        }
    }
    }
`;

const EmailLink = ({
    loginUser,
    customAuth,
    match
}) => {
    const history = useHistory();
    const [authinticated, setAuthinticated] = useState(false)
    const [mfacode, setMFAcode] = useState('')

    useEffect(() => {
     
        const isUserAuthenticated = customAuth.isUserAuthenticated();
        setAuthinticated(isUserAuthenticated);
    }, [setAuthinticated, customAuth])

    useQuery(VALIDATE_LINK, {
        variables: {
            link: match.params.link
        },
        onCompleted: (data) => {
            if (data.validateLink === false) {
                history.push('/login');
            }
        },
        onError: () => {
            history.push('/login');
        }
    })

    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    const [login, { loading, data }] = useMutation(LOGIN_GQL, {
        onCompleted(data) {
            const { token, user, url, companyid } = data.magiclink;
            localStorage.setItem("companyid", companyid);
            loginUser({
                token,
                companyid,
            });

            window.location.href = url;
        },
        onError: handleError,
    });

    const updateState = (event) => {
        setMFAcode(event.target.value);
    }

    const onLogin = () => {
        login({
            variables:
            {
                "input": {
                    "link": match.params.link,
                    mfacode
                }
            }
        });
    }

    if (authinticated) {
        return <Redirect to='/app' />
    }

    return (
        <Container>
            <Row>

                <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4"></div>
                <div className="col-12 col-md-4 text-center">
                    <label for="exampleInputEmail1">Enter Code</label>
                    <input name="mfacode" type="text" onChange={updateState} className="form-control" id="mfacode" placeholder="Enter Code"></input>

                    <div className="text-center">
                        <div className="form-check-center">

                        </div>
                        <button type="button" className="btn btn-outline-secondary" onClick={onLogin}>Login</button>
                    </div>
                </div>
                <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4"></div>
            </Row>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />
        </Container>
    )
}


const mapStateToProps = (state) => ({
    customAuth: state.authProvider,
})

const mapDispatchToProps = {
    loginUser
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailLink)
