import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ToastContainer, toast } from 'react-toastify';
import SetupForm from './SetupForm';
import Spinner from '../../componets/Spinner';
import api from '../../connection/api';

// import {
//     gql,
//     useMutation,
// } from "@apollo/client";

// const CREATE_GQL = gql`
//     mutation StartPaymentIntent {
//         startPaymentIntent
//         {
//             stripeId
//             client_secret
//             status
//         }
//     }
//     `

const stripePromise = loadStripe('pk_test_51NTkOqSJ8IEQuptXmiJiD33mfTHj1ClxHGTwdya2oGDcQYktGEOKRWb8Cb3wMKTuLmqVm0wTcTEiMAcKUBjyRDFv00Ohu3WJc0');

export const PaymentMethod = () => {
    // const [options, setOptions] = useState();
    const [clientSecret, setClientSecret] = useState("");

    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    // const [createIntent] = useMutation(CREATE_GQL, {
    //     onCompleted: ({ startPaymentIntent }) => {
    //         setOptions({
    //             clientSecret: startPaymentIntent.client_secret
    //         });
    //     },
    //     onError: handleError,
    // })

    useEffect(() => {
        // createIntent();
        // Create PaymentIntent as soon as the page loads
       
        fetch(api.url() + "/create-payment-intent", {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        })
            .then((res) => res.json())
            .then((data) => {  setClientSecret(data.clientSecret) });
        
    }, [])

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    // const options = {
    //     // passing the client secret obtained from the server
    //     clientSecret: 'seti_1Kg9aXCtUMWWTCpglWr63Sbu_secret_LMtNwQ7xA6AyY5qJJGlwPHrClCW8mga',
    // };
    if (!options) return (<>Loading...</>)

    return (
        <>
            {!clientSecret ?
                <Spinner />
                :
                <>
                    <Elements stripe={stripePromise} options={options}>
                        <SetupForm />
                        {/* <CheckoutForm1 /> */}
                    </Elements>

                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </>

            }
        </>
    );
}
