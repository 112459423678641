import React, { useState, useEffect } from 'react'
import {
  gql,
  useQuery,
  useMutation
} from "@apollo/client";
import Spinner from "../../componets/Spinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../connection/api'

import json2csv from 'json2csv';


const BATCHES_GQL = gql`
{
    approvedrequests {
        id
        downloadData
        downloaded
        requester{
                id
                name
        }
        company{
          id
          code
          name
        }
        checkDate{
            id
            date
            formattedDate
        }
    }
}`;

const DOWNLOAD_REQUEST = gql`
  mutation($requestId: ID!, $companyId: ID!)
  {
    downloadPayRequest(id: $requestId, companyId: $companyId ) {
      id
      downloadData
      downloaded
    }
  }
`;







const Batches = (employeeId) => {
  const [batches, setBatches] = useState([]);
  const [code, setCode] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [companytemplate, setCompanytemplate] = useState([]);

  const handleError = (error) => {
    if (error.message) {
      toast.error(error.message);
    } else if (error.networkError) {
      toast.error('A network error has occured');
      console.error('Error', error.networkError.result.errors);
    } else {
      toast.error('An unknown error has occured');
    }
  }
  useEffect(() => {
    ApprovedBatches();
    getCode();
    getCompanyTemplate();

  }, [])

  const getCompanyTemplate = async () => {
      
    await fetch(api.url() + '/template/getcompanytemplate', {
       method: 'GET',
       headers: {
         'Authorization': 'Bearer ' + localStorage.getItem('token')
       }
     }).then(response => response.json())
       .then(data => {
        setCompanytemplate(data)
       
       })
       .catch(error => {
         // handleError(error);
       });
      
   }

  const ApprovedBatches = async () => {

    fetch(api.url() + '/paymentrequest/getStatus?status=APPROVED', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(data => {
        setBatches(data)
        setIsLoading(false)

      })
      .catch(error => {
        handleError()
      });

  }

  const getCode = async () => {

    fetch(api.url() + '/code', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(data => {
        setCode(data)

      })
      .catch(error => {
        handleError(error);

      });

  }





  // array full of fake data
  // const { loading } = useQuery(BATCHES_GQL,
  //   {
  //     onCompleted: (data) => {
  //       const { approvedrequests } = data;
  //       const sortedRequests = sortRequests(approvedrequests);
  //       setBatches(sortedRequests);
  //     },
  //     onError: handleError,
  //   });

  const sortRequests = (approvedrequests) => {
    const sortedRequests = [...approvedrequests].sort(function (a, b) {
      if (b.downloaded !== true && a.downloaded) {
        return 1;
      } else if (b.downloaded && a.downloaded !== true) {
        return -1;
      }
      return b.checkDate.date - a.checkDate.date;
    });
    return sortedRequests;
  }

  const updatePaymentRequest = async (paymentRequestId) => {

    fetch(api.url() + '/paymentrequest/updateDownloaded?id=' + paymentRequestId, {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(data => {
        setCode(data)

      })
      .catch(error => {
        handleError(error);

      });

  }

  const downloadPayrequest = async (paymentRequestId) => {
    setIsLoading(true)
    await fetch(api.url() + '/csv/download/' + paymentRequestId, {
      method: 'GET',
    }).then(response => response.json())  // Use response.text() instead of response.json()
      .then(csvContent => {
        updatePaymentRequest(paymentRequestId);
        const csvReport = {
          data: csvContent.data,
          headers: csvContent.header,
          filename: paymentRequestId + '.csv',
        };

        
        if(companytemplate.templateId==1){

          const csv = convertToCSV(csvReport);
          const blob = new Blob([csv], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = paymentRequestId + '.csv';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
  
          
          setIsLoading(false);
        }
        else if(companytemplate.templateId==2){
          alert("2nd template");
          setIsLoading(false);
        }
        else if(companytemplate.templateId==3){
          alert("3rd template");
          setIsLoading(false);
        }
        else if(companytemplate.templateId==4){
          alert("4th template");
          setIsLoading(false);
        }
        else if(companytemplate.templateId==5){
          alert("5th template");
          setIsLoading(false);
        }
       

      })
      .catch(error => {
        
        handleError(error);
      });


  }

  function convertToCSV(json) {
    const fields = Object.keys(json.data[0]);
    const csv = json2csv.parse(json.data, fields);
    return csv;
  }

  function download_csv_file(paymentRequestId) {

    downloadPayrequest(paymentRequestId);


  };

  // var hiddenElement = document.createElement('a');
  // hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
  // hiddenElement.target = '_blank';

  // //provide the name for the CSV file to be downloaded
  // hiddenElement.download = 'BatchDownload.csv';
  // hiddenElement.click();

  return (
    <>{
      isLoading ? <Spinner />
        :
        <div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Code</th>
                <th scope="col">Company</th>
                <th scope="col">Department</th>
                <th scope="col">Date</th>
                <th scope="col">Downloaded</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {batches.map((request, index) => (
                <tr key={request.Id}>
                  <th scope="row">{request.company.code}</th>
                  <td>{request.company.name}</td>
                  <td>{request.department.name}</td>
                  <td>{request.checkDate.formattedDate}</td>
                  <td>
                    <span onClick={() => download_csv_file(request.Id)} style={{ cursor: 'pointer' }}>
                      {request.downloaded && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-patch-check-fill" viewBox="0 0 16 16">
                        <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                      </svg>}
                      {request.downloaded === false && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>}
                    </span>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />
        </div>
    }
    </>
  )

};

export default Batches;
