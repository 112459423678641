import { useState } from 'react';
import Spinner from '../../componets/Spinner';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../connection/api'
import {
    useQuery,
    gql,
    useMutation,
} from "@apollo/client";
import RequestReview from '../../componets/RequestReview';

// const REQUESTS_GQL = gql`
//     {
//         approverRequests {
//             id
//             status
//             total
//             reason
//             requester{
//                 id
//                 name
//             }
//             checkDate{
//                 id
//                 date
//                 formattedDate
//             }
//             group{
//                 id
//                 name
//             }
//             lineitems {
//                 id
//                 status
//                 amount
//                 reason
//                 code {
//                     id
//                     label
//                 }
//                 employee {
//                     id
//                     name
//                     number
//                     email
//                     title
//                     status
//                 }

//             }
//         }
//     }
// `;

// const APPROVE_GQL = gql`
//     mutation ApproveRequest($id: ID!) {
//         approvePayRequest(input: {
//         id: $id
//         }){
//             id
//             status
//             total
//             lineitems {
//                 id
//                 amount
//                 status
//                 code {
//                     id
//                     label
//                 }
//                 employee {
//                     id
//                     name
//                     number
//                     email
//                     title
//                     status
//                 }
//             }
//         }
//     }
// `

// const REJECT_GQL = gql`
//     mutation RejectRequest($id: ID!, $reason: String!) {
//         rejectPayRequest(input: {
//         id: $id
//         reason: $reason
//         }){
//             id
//             status
//             total
//             reason
//             lineitems {
//                 id
//                 amount
//                 status
//                 reason
//                 code {
//                     id
//                     label
//                 }
//                 employee {
//                     id
//                     name
//                     number
//                     email
//                     title
//                     status
//                 }
//             }
//         }
//     }
// `

const MassApproval = ({ history, match }) => {
    const [requests, setRequests] = useState();

    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    // const { loading } = useQuery(REQUESTS_GQL,
    //     {
    //         onCompleted: (data) => {
    //             setRequests([...data.approverRequests].sort((a, b) => {
    //                 if (a.status === 'PENDING' && b.status !== 'PENDING') {
    //                     return -1
    //                 }
    //                 return 0;
    //             }))
    //         },
    //         onError: handleError,
    //     });

    // const [approveRequest] = useMutation(APPROVE_GQL, {
    //     onCompleted: (data) => {
    //         updateRequest(data.approvePayRequest);
    //     },
    //     onError: handleError,
    // })



    const onApproval = (id, index,) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Are You Sure?")) {

            fetch(api.url() + '/paymentrequest/updatePaymentRequestApproval?status=APPROVED&paymentRequestId=' + id
                , {
                    method: 'PUT',

                    headers: new Headers({
                        'Content-type': 'application/json; charset=UTF-8',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }),
                }).then(response => response.json())
                .then(data => {
                    window.location.reload(false);

                })
                .catch(error => {
                    // Handle any errors
                    handleError(error)
                });
        }
    }
    // const [rejectRequest] = useMutation(REJECT_GQL, {
    //     onCompleted: (data) => {
    //         updateRequest(data.rejectPayRequest);
    //     },
    //     onError: handleError,
    // })

    const updateRequest = (newRequest) => {
        const newRequests = [...requests];
        const index = newRequests.findIndex(request => request.id === newRequest.id);
        newRequests[index] = {
            ...newRequests[index],
            ...newRequest,
            open: false
        };
        setRequests(newRequests);
    }

    // const onReview = (requestId) => {
    //     const newRequests = [...requests];
    //     const index = newRequests.findIndex(request => request.id === requestId);
    //     newRequests[index] = {
    //         ...newRequests[index],
    //         open: !newRequests[index].open
    //     };
    //     setRequests(newRequests);
    // }
    const onReject = (id) => {
        let reason = prompt("Please enter your reason:", "");
        if (reason) {
            fetch(api.url() + '/paymentrequest/updatePaymentRequestApproval?status=REJECTED&paymentRequestId=' + id + '&reason=' + reason, {
                method: 'PUT',

                headers: new Headers({
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }),
            }).then(response => response.json())
                .then(data => {
                    window.location.reload(false);
                })
                .catch(error => {
                    // Handle any errors
                    handleError(error)
                });


        }
    }

    // if (!requests || loading) return <Spinner></Spinner>

    const buttonWrapper = ({ request, index }) => (
        request.approverStatus === 'PENDING' && request.status === 'PENDING' && <>
            <button onClick={() => onApproval(request.Id, index)} type="button" className="btn btn-success">Approve</button>
            <button onClick={() => onReject(request.Id, index)} type="button" className="btn btn-danger">Reject</button>
        </>
    )

    return (
        <>
            <RequestReview requestData={requests} onApproval={onApproval} onReject={onReject} buttonComponent={buttonWrapper}>
            </RequestReview>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />
        </>
    )
}

export default MassApproval;