import React, { useState, useRef } from 'react'
import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../../componets/Spinner";
import {Nav, Navbar, NavDropdown} from 'react-bootstrap'; 
import Planet from '../../Assets/fwdartwork/Planet-Only-Logo.png';
import Payquest from '../../Assets/fwdartwork/ItsAboutPayroll_Branding_Board_PayQuest_App.png';
import api from '../../connection/api'
import ReCAPTCHA from 'react-google-recaptcha';

import "../../styles/Signup.css"
import {
    gql,
    useMutation,
} from "@apollo/client";

const SIGNHUP_GQL = gql`
    mutation Signup($name: String!, $companyName: String!, $email: String!) {
        signup(input: {
            name: $name
            companyName: $companyName
            email: $email
        })
        {
            id
            name
        }
    }`

export const Signup = () => {
    const [state, setState] = useState({})
    const [signUpState, setSignUpState] = useState('ready')
    const [captchaResponse, setCaptchaResponse] = useState(null);


    const captchaRef = useRef(null);
    const handleError = (error) => {
        setSignUpState('error')
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    const [signUpMutation] = useMutation(SIGNHUP_GQL, {
        onCompleted: (data) => {
            setSignUpState('completed');
        },
        onError: handleError,
    })

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        })
    }

    const getSignup = async () => {
        const token = captchaRef.current.getValue()
        const data = {
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email.toLowerCase(),
            company: {
                name: state.companyName
            },
            token: token
        };


        fetch(api.url() + '/employee/signup', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Content-type': 'application/json; charset=UTF-8',
            }),
        }).then(response => response.text())
            .then(data => {
                if (data != "Success") {
                    toast.error(data);
                    setSignUpState('error')
                    // handleError(data);
                }
                else {
                    setSignUpState("completed")
                }

            })
            .catch(error => {
                handleError(error);

            });

    }
    const handleSubmit = (event) => {

        event.preventDefault();
        // const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //     event.preventDefault();
        //     event.stopPropagation();
        //     return;
        // }


        setSignUpState('loading');
        getSignup()
        // signUpMutation({
        //     variables: state
        // });
    }



    if (signUpState === 'loading') {
        return <Spinner />
    }
    if (signUpState === 'completed') {
        return (
            <Container fluid>
                <Card>
                    <Row>
                        <Col className="center">
                            You are ready to get started.<br />
                            Check your email to activate your account.
                        </Col>
                        <Col className="center">
                            <img src="Welcome.webp" width="75%" alt="Person walking through door" />
                        </Col>
                    </Row>
                </Card>
            </Container>
        )
    }
    return (
        <>

            <div>
              <Navbar bg="#fff" expand="md">
                <div style={{ paddingLeft: '5px', textAlign: 'center', position: 'absolute', paddingBottom: '125px'}}>
                <img src={Payquest} alt='planetLogo' style={{width:'150px', height:'40px'}}></img>
                </div>
                 
              </Navbar>
              </div>

        <Container fluid >
             {/* <Card>  */}
                <Row>
                    <Col className="center">
                        <Form noValidate onSubmit={handleSubmit}>
                            <div className="subtitle">Get Started</div>
                            <Form.Group className="mb-3" style={{width: '78%', paddingLeft: '330px'}} controlId="formBasicCompany">
                                {/* <Form.Label>Company</Form.Label> */}
                                <Form.Control
                                    type="text"
                                    name="companyName"
                                    placeholder="Company"
                                    onChange={handleChange}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Please enter a Company Name
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" style={{width: '78%', paddingLeft: '330px'}} controlId="formBasicFirstName">
                                {/* <Form.Label>FirstName</Form.Label> */}
                                <Form.Control type="text" name="firstName" placeholder="FirstName" onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group className="mb-3" style={{width: '78%', paddingLeft: '330px'}} controlId="formBasicLastName">
                                {/* <Form.Label>LastName</Form.Label> */}
                                <Form.Control type="text" name="lastName" placeholder="LastName" onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group className="mb-3" style={{width: '78%', paddingLeft: '330px'}} controlId="formBasicEmail">
                                {/* <Form.Label>Email address</Form.Label> */}
                                <Form.Control type="email" name="email" placeholder="Company email" onChange={handleChange} required />

                                <div style={{ margin: "5px", display: "flex", justifyContent: "center" }}>
                                <ReCAPTCHA
                                        sitekey="6Lcz6UUpAAAAAOg8hdQp66x6OVSJOQwd7-A80hGz"
                                        onChange={(value) => setCaptchaResponse(value)}
                                        ref={captchaRef}
                                    />
                                </div>
                                <Form.Text className="text-muted">
                                    We will send you an activation email to this address.
                                </Form.Text>
                            </Form.Group>


                            <Button variant="primary" type="submit">
                                Register
                            </Button>
                        </Form>
                    </Col>
                    {/* <Col className="center">
                        <img src="Welcome.webp" width="75%" alt="Person walking through door" />
                    </Col> */}
                </Row>
            {/* </Card> */}
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />
        </Container>
        </>
    )
}
