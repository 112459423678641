import React from 'react'
import Header from '../componets/Header';

function Blocked() {
    return (

        <div>
            <Header/>
            Your Company is Blocked. Pease contact Admin
        </div>
    )
}

export default Blocked;
