import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentStatus from './PaymentStatus';
import { Invoice } from './Invoice';
import { Redirect } from 'react-router-dom';

const stripePromise = loadStripe('pk_test_51KRN8OCtUMWWTCpgkAI4m0qcIZ0bZ3YpsVGnG4nq4GrJd29TmRHpYHQhYDfG7maqKAoYRyZU4VThZkMFmu56lywf00r3lsqxTv');

export const ThankYou = () => {
    const [redirect, setRedirect] = useState(false)
    useEffect(() => {
        setTimeout(function () {
            setRedirect(true);
        }, 5000);
    }, [])

    if (redirect) {
        return <Redirect to={'app/manage/billing'} />
    }

    return (
        <Elements stripe={stripePromise}>
            <PaymentStatus />
        </Elements>
    )
}
