import React from 'react'
import './UserEdit.css';
import { useState, useEffect } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { useParams, useLocation } from 'react-router-dom'
import { Multiselect } from "multiselect-react-dropdown";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    useQuery,
    useLazyQuery,
    gql,
    useMutation,
} from "@apollo/client";

const USER_GQL = gql`
      query user($userId: ID!) {
        user(id: $userId) {
          id
          name
          email
          status
          roles
          companies {
              id,
              name
          }
        }
      }
    `;

const COMPANY_GQL = gql`
{
    companies {
        id
        name
    }
}`;

const CREATE_GQL = gql`
      mutation($name: String!, $email: String!, $password: String, $status: UserStatus, $companyids: [String], $roles: [String]) {
        createUser(
          input: {
            name: $name
            email: $email
            password: $password
            status: $status
            companyids: $companyids
            roles: $roles
          }
        ) {
          id
        }
      }
    `

const UPDATE_GQL = gql`
  mutation($id: ID!, $name: String!, $email: String!, $password: String, $status: UserStatus, $companyids: [String], $roles: [String] ) {
    updateUser(
      input: {
        id: $id
        name: $name
        email: $email
        password: $password
        status: $status
        companyids: $companyids
        roles: $roles
      }
    ) {
        id
          name
          email
          status
          companies {
              id,
              name
          }
    }
  }
`

function useQueryParam() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const UserEdit = (props) => {
    const QUERY = useQueryParam();

    const { history, match } = props;
    const { userid } = useParams()
    const [state, setState] = useState({
        name: '',
        email: '',
        password: '',
        status: 'ACTIVE',
        companyids: [],
        roles: ['admin']
    });

    const [objectArray, setObjectArray] = useState([
    ])


    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    useQuery(COMPANY_GQL, {
        onCompleted: ({ companies }) => {
            setObjectArray(companies);
            const companyids = QUERY.get('cid') ? [QUERY.get('cid')] : [];
            setState({
                ...state,
                companies: companies.filter(company => company.id == QUERY.get('cid')),
                companyids,
            })
        },
        onError: handleError,
    })

    const [getUser] = useLazyQuery(USER_GQL, {
        onCompleted: ({ user }) => {
            setState({
                ...user,
                companyids: user.companies.map(company => company.id)
            });
        },
        onError: (error) => {
            console.error(error.networkError.result.errors);
        }
    });

    useEffect(() => {
        if (userid) {
            getUser({
                variables:
                    { userId: userid }
            })
        }
    }, [userid, getUser])

    const [createUser] = useMutation(CREATE_GQL, {
        onCompleted: (data) => {
            returntousers();
        },
        refetchQueries: [
            'GetUser' // Query name
        ],
        onError: handleError,
    })

    const [updateUser] = useMutation(UPDATE_GQL, {
        onCompleted: (data) => {
            returntousers();
        },
        onError: handleError,
    })

    const updateState = (event) => {
        const { name, value } = event.target;
        setState({
            ...state,
            [name]: value,
        })
    }

    const updateArray = (event) => {
        const { name, value } = event.target;
        setState({
            ...state,
            [name]: [value],
        })
    }


    const onSave = async () => {
        if (userid) {
            await updateUser({
                variables: {
                    ...state,
                    id: userid
                }
            })
        } else {
            await createUser({
                variables: state
            })
        }
    }

    const returntousers = () => {
        let newPath = match.path.substring(0, match.path.substring(1).lastIndexOf('/') + 1);
        if (match.path.includes('edit')) {
            //removes the ID and edit
            newPath = newPath.substring(0, newPath.substring(1).lastIndexOf('/') + 1);
        }

        history.push(newPath);
    }
    const onSelect = (selectedList, selectedItem) => {
        //Add the selected company Id to the state.companyids
        //Create a copy of the state.companyids
        const companyids = [...state.companyids];

        //Push the selected company into the copy
        companyids.push(selectedItem.id);
        //Set the state with the new list

        setState({
            ...state,
            companyids
        })
       
    }

    const onRemove = (selectedList, removedItem) => {
        //Remove the selected company Id to the state.companyids
        const companyids = [...state.companyids];

        //Remove the selected company into the copy
        const filtered = companyids.filter(companyId => companyId !== removedItem.id);
        //Set the state with the new list

        setState({
            ...state,
            companyids: filtered
        })
    }

    return (
        <>
            <div style={{ width: '80%' }}>
                {userid && <>Edit </>}
                {!userid && <>New </>}
                User
                <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4"></div>
                <div className="col-12 col-md-4 text-center">
                    <InputGroup className="mb-3">
                        <FormControl name="name" placeholder="Name" aria-label="Name" value={state.name} onChange={updateState} aria-describedby="inputGroup-sizing-sm" />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <FormControl name="email" placeholder="Email" aria-label="Email" value={state.email} onChange={updateState} aria-describedby="inputGroup-sizing-sm" />
                    </InputGroup>
                    {false && userid &&
                        <InputGroup className="mb-3">
                            <FormControl name="password" placeholder="User Password" type="password" aria-label="User Password" value={state.password} onChange={updateState} aria-describedby="inputGroup-sizing-sm" />
                        </InputGroup>
                    }
                    {!userid && <div>A temporary password will be sent to the email address.</div>}
                    <InputGroup className="mb-3">
                        <select name="status" onChange={updateState} value={state.status}>
                            <option value="ACTIVE">Active</option>
                            <option value="INACTIVE">Inactive</option>
                        </select>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <select name="roles" onChange={updateArray} value={state.role}>
                            <option value="admin">Admin</option>
                            <option value="approver">Approver/Requester</option>
                        </select>
                    </InputGroup>

                    <Multiselect
                        options={objectArray}
                        selectedValues={state.companies}
                        onSelect={onSelect}
                        onRemove={onRemove}
                        displayValue="name"
                        placeholder="Company"
                        showCheckbox={true}

                    />
                </div>
                <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4">
                </div>
                <div>
                    <button type="button" className="btn btn-secondary" onClick={returntousers}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={onSave}>Save</button>
                </div >
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />
        </>
    )
}

