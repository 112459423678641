import React from 'react'
import '../styles/appcard.css';

function AppCard(props) {
    const { title, color } = props;
    return (
        <div className="box-shadow appcard position-relative" style={{ backgroundColor: color }}>
            <div className="position-absolute top-50 start-50 translate-middle">
                {title}
            </div>
        </div>
    )
}

export default AppCard
