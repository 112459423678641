import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { formatter } from '../utils/currency';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../connection/api'

import {
    gql,
    useMutation,
} from "@apollo/client";

const REJECT_GQL = gql`
    mutation RejectLineItem($id: ID!, $reason: String!, $requestId: ID!) {
        rejectLineItem(input:  {
            id: $id
            reason: $reason
            requestId: $requestId
        }){
            id
            status
            reason
        }
    }
`

const RequestApproval = ({ request, showReject, setIsLoading }) => {


    const [lineitems, setLineitems] = useState();
    const [requests, setRequests] = useState([]);






    // const index = newRequests.findIndex(request => request.paymentRequestId === requestId);
    // newRequests[index] = {
    //     ...newRequests[index],
    //     open: !newRequests[index].open
    // };
    // setRequests(newRequests);







    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    const [updateRequest] = useMutation(REJECT_GQL, {
        onCompleted: (data) => {
         
            const newItems = lineitems.map(item => {
                if (item.id === data.rejectLineItem.id) {
                    item = {
                        ...item,
                        ...data.rejectLineItem
                    }
                }
                return item;
            })
            setLineitems(newItems);
        },
        onError: handleError,
    })

    const approveRequest = (id) => {
        const reason = prompt("Please enter your reason:", "");
        if (reason) {
            updateRequest({
                variables: {
                    id,
                    requestId: request.id,
                    reason
                }
            })

        }
    }

    const reject = (id) => {
        const reason = prompt("Please enter your reason:", "");
        if (reason) {
            updateRequest({
                variables: {
                    id,
                    requestId: request.id,
                    reason
                }
            })

        }
    }

    const rejectRequest = (id, payreqid, employeeId) => {
        const reason = prompt("Please enter your reason:", "");
        if (reason) {

            fetch(api.url() + '/paymentrequestdetails/updateStatus?status=REJECTED&id=' + id + '&reason=' + reason + '&payreqid=' + payreqid + '&employeeId=' + employeeId, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(response => response.json())
                .then(data => {
                    window.location.reload(false);
                })
                .catch(error => {
                    handleError()
                });

        }


    }

    return (
        <Container>
            <Row>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Employee ID </th>
                            <th scope="col">Full Name </th>
                            <th scope="col">Job Title  </th>
                            <th scope="col">Email </th>
                            <th scope="col">Amount</th>
                            <th scope="col">Codes</th>
                            <th scope="col">Reason </th>
                            <th scope="col">Employee Status </th>
                            <th scope="col">Status </th>

                        </tr>
                    </thead>

                    <tbody>

                        {request.paymentdetails.map((req, index) => (
                            <tr key={req.Id}>
                                <td>{req.employee.number}</td>
                                <td>{req.employee.firstName}</td>
                                <td>{req.employee.title}</td>
                                <td>{req.employee.email}</td>
                                <td>{formatter.format(req.amount)}</td>
                                <td>{req.code.label}</td>
                                <td>{req.reason}</td>
                                <td>{req.employee.status}</td>
                                <td>{req.status}</td>

                                <td>
                                    {request.status == "PENDING" && req.status == "PENDING" &&
                                        <svg onClick={() => { rejectRequest(req.Id, request.Id, req.employee.employeeId) }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-square text-danger" viewBox="0 0 16 16">
                                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    }
                                </td>


                            </tr>
                        ))}


                        {/* <button onClick={() => approveRequest(request.paymentRequestId)}  type="button" class="btn btn-success">Approve</button>
                                    <button  class="btn btn-danger" onClick={() => reject(request.paymentRequestId)} type="button" >Reject</button> */}

                    </tbody>
                </table>
            </Row>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />
        </Container >
    )
}
export default RequestApproval