import { useState, Fragment, useEffect } from 'react';
import Spinner from '../componets/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import RequestApproval from '../componets/pendingRequest';
import { formatter } from '../utils/currency';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../connection/api'
import { Modal } from 'react-bootstrap';
import {
    useQuery,
    gql,
    useLazyQuery,
    useMutation,
} from "@apollo/client";
import request from './request';






const ClientManagement = ({ history, match, buttonComponent }) => {
    const [clientManagement, SetclientManagement] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [state, setState] = useState({
        remark: '',
        status: '',
        employee:''

    });
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [status, setStatus] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(async () => {
        getcompany();
        getClientManagement();
    }, [])
    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    

    const updateState = (event) => {
        const { name, value } = event.target;
        setState({
            ...state,
            [name]: value,
        })
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleViewClick = (request) => {
        setSelectedRequest(request);
        setShowModal(true);
    };

    const handleBlock = (request) => {
        setIsLoading(true)

        fetch(api.url() + '/company/block', {
            method: 'PUT',
            body: JSON.stringify(request),
            headers: new Headers({
                'Content-type': 'application/json; charset=UTF-8',
            }),
        })
            .then(response => {
                return response.text()
            })
            .then(data => {
                toast.success(data);
                getClientManagement();


            }).catch(error => {

            });
      
    };

    const handleUnblock= (request) => {
        setIsLoading(true)
        fetch(api.url() + '/company/unblock', {
            method: 'PUT',
            body: JSON.stringify(request),
            headers: new Headers({
                'Content-type': 'application/json; charset=UTF-8',
            }),
        })
            .then(response => {
                return response.text()
            })
            .then(data => {
                toast.success(data);
                getClientManagement();


            }).catch(error => {

            });   
    };

    const handleDelete= (request) => {

        const isConfirmed = window.confirm('Are you sure?');
        if (isConfirmed) {
            setIsLoading(true)

            fetch(api.url() + '/company/delete', {
                method: 'DELETE',
                body: JSON.stringify(request),
                headers: new Headers({
                    'Content-type': 'application/json; charset=UTF-8',
                }),
            })
                .then(response => {
                    return response.text()
                })
                .then(data => {
                    toast.success(data);
                    getClientManagement();
    
    
                }).catch(error => {
    
                });
            

        }
    

        

      
      
        
    };

    





    const getcompany = async () => {
        setIsLoading(true)
        fetch(api.url() + '/company', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                
                setCompanies(data);

            }).catch(error => {

            });
    }


    const getClientManagement = async () => {
        setIsLoading(true)
        fetch(api.url() + '/company/getClientManagement', {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }),
        }).then(response => response.json())
            .then(data => {
                getcompany();
                SetclientManagement(data);
                setIsLoading(false)
            })
            .catch(error => {
                handleError(error);

            });


    }

    



    const filteredRequests = clientManagement.filter(request => {
        const companyFilter = selectedCompany
            ? request.company.name === selectedCompany
            : true;
            const IsBlock = selectedCompany
            ? request.company.isBlocked === selectedCompany
            : true;

        
        return companyFilter ;
    });


    return (
        <>{
            isLoading ? <Spinner />
                :
                <Container>

                    <div className="filter-container">
                        <h6>Filter By</h6>
                        <select
                            value={selectedCompany}
                            onChange={e => setSelectedCompany(e.target.value)}>
                            <option value="">All Companies</option>
                            {companies.map(company => (
                                <option key={company.id} value={company.name}>
                                    {company.name}
                                </option>
                            ))}
                        </select>


                       
                    </div>
                    <Row>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Company</th>
                                    <th scope="col">Code</th>
                                    <th scope="col">Total Users</th>
                                    <th scope="col">Current Subscription</th>
                                    <th scope="col">Pending Invoice</th>
                                    <th scope="col">Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {filteredRequests && filteredRequests.map((request, index, lineitem,) => (
                                    <Fragment key={request.company.companyId}>
                                        <tr key={request.company.companyId}>
                                            <td>{request.company.name}</td>
                                            <td>{request.company.code}</td>
                                            <td>
                                                {request.company.userCount}
                                            </td>
                                            <td>
                                                    {request.subscription
                                                        ? (request.subscription.subscriptionName !== null
                                                        ? request.subscription.subscriptionName
                                                        : 'null')
                                                        : 'N/A'}
                                                    </td>
                                                    <td>
                                                    {request.subscription
                                                        ? (request.subscription.subscriptionName !== null
                                                        ? request.subscription.subscriptionName
                                                        : 'null')
                                                        : 'N/A'}
                                                    </td>
                                            
                                            <td>
                                                {request.company.isBlocked==0 ?  <button onClick={() => handleBlock(request.company)} type="button" className="btn btn-warning">Block</button> : <button onClick={() => handleUnblock(request.company)} type="button" className="btn btn-warning">Unblock</button>}
                                               
                                                <button onClick={() => handleDelete(request.company)} type="button" className="btn btn-danger">Delete</button>
                                            </td>
                                        </tr>

                                    </Fragment>
                                ))}
                            </tbody>
                        </table>
                       


                    </Row>

                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </Container >
        }
        </>
    )
}

export default ClientManagement;