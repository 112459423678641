import React from 'react'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import CVSUpload from '../../componets/CVSUpload'
import api from '../../connection/api'
import {
    useQuery,
    gql,
} from "@apollo/client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../componets/Spinner';
// const CODE_GQL = gql`
//     query codes {
//       codes {
//         id
//         code
//         label
//       }
//     }
//   `;

export default function Codes(props) {
    const [data, setdata] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    // const { data } = useQuery(CODE_GQL,
    //     {
    //         onCompleted: (data) => {
    //             console.log('data', data)
    //         },
    //         onError: handleError,
    //     });


    useEffect(() => {

        getCode();

    }, [])

    const getCode = async () => {

        fetch(api.url() + '/code', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then(response => response.json())
            .then(data => {
                setdata(data)
                setIsLoading(false)
            })
            .catch(error => {
                handleError(error);

            });

    }


    return (
        <>{
            isLoading ? <Spinner />
                :
                <div>
                    <Link to={`${props.match.url}/newcode`}>
                        <button type="button" className="btn btn-primary">New Code</button>
                    </Link>


                    <CVSUpload type="code" refetchQL="codes" exampleFile="/csv/code.csv"></CVSUpload>


                    <table className="table">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Codes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((code, index) => (
                                <tr key={code.codesId}>

                                    <td>
                                        <Link to={`${props.match.url}/editcode/${code.codesId}`}>
                                            {/* {code.name} */}
                                            Edit
                                        </Link>
                                    </td>
                                    <td>{code.label}</td>

                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </div>
        }
        </>

    )
}