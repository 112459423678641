import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../connection/api";
import { gql, useMutation } from "@apollo/client";
import ReCAPTCHA from "react-google-recaptcha";

const RESET_GQL = gql`
  mutation ResetPassword($key: String!, $password: String!) {
    resetPassword(input: { password: $password, key: $key }) {
      id
      name
    }
  }
`;

const REQUEST_GQL = gql`
  mutation RequestReset($email: String!) {
    requestResetPassword(email: $email)
  }
`;

function PasswordReset({ match, history }) {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [score, setScore] = useState(0);
  const [canSave, setCanSave] = useState(false);
  const { key } = useParams();
  const [captchaResponse, setCaptchaResponse] = useState(null);

  const captchaRef = useRef(null);
  const handleError = (error) => {
    if (error.message) {
      toast.error(error.message);
    } else if (error.networkError) {
      toast.error("A network error has occured");
      console.error("Error", error.networkError.result.errors);
    } else {
      toast.error("An unknown error has occured");
    }
  };
  const [resetPassword] = useMutation(RESET_GQL, {
    onCompleted: (data) => {
      history.push("/");
    },
    onError: handleError,
  });

  const requestReset = (token) => {
    const data = {
      token: token.token,
    };
    fetch(api.url() + "/user/sendemail?email=" + email, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: new Headers({
        "Content-type": "application/json; charset=UTF-8",
      }),
    })
      .then((data) => {
        setEmailSent(true);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // const [requestReset] = useMutation(REQUEST_GQL, {
  //     onCompleted: (data) => {
  //         setEmailSent(true);
  //     },
  //     onError: handleError,
  // })
  const changePassword = (e) => {
    setPassword(e.target.value);
  };

  const changeEmail = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  const changeScore = (score, feedback) => {
    setScore(score);
    setCanSave(score >= 2);
  };

  const onSave = () => {
    const newPassword = password;
    const specialCharactersRegex = /[#$]/;
    if (specialCharactersRegex.test(newPassword)) {
      toast.error(
        "Password should not contain special characters like #, $, or &."
      );
    } else {
      if (score < 2) return;
      fetch(
        api.url() + "/employee/setpassword?password=" + password + "&" + key,
        {
          method: "PUT",
          headers: new Headers({
            "Content-type": "application/json; charset=UTF-8",
          }),
        }
      )
        .then((data) => {
          history.push("/");
        })
        .catch((error) => {
          // Handle any errors
          toast.error(error);
        });
    }
  };

  const onRequestReset = () => {
    if (email) {
      const token = captchaRef.current.getValue();
      requestReset({
        variables: { email },
        token: token,
      });
    } else {
      toast.error("please enter your email");
    }
  };

  if (!key) {
    if (emailSent) {
      return (
        <div>
          If we find an account for your email you will receive a password reset
          email.
          <br />
          Please be patient. It can take a few minutes to receive.
        </div>
      );
    }
    return (
      <div>
        <div>
          <strong>
            Password Reset
            <br />
          </strong>
          <label for="email">Please enter your email</label>
          <br />
          <input
            name="email"
            type="email"
            id="email"
            size="30"
            required
            value={email.toLowerCase()}
            onChange={changeEmail}
          ></input>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            marginBottom: "5px",
          }}
        >
          <ReCAPTCHA
            sitekey="6Lcz6UUpAAAAAOg8hdQp66x6OVSJOQwd7-A80hGz"
            onChange={(value) => setCaptchaResponse(value)}
            ref={captchaRef}
          />
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={onRequestReset}
        >
          Submit
        </button>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
      </div>
    );
  }
  return (
    <div style={{ width: "200px" }}>
      <div>
        <label for="password">New Password</label>
        <br />
        <div
          style={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <input
            name="password"
            type="password"
            value={password}
            onChange={changePassword}
          />
          <PasswordStrengthBar
            password={password}
            onChangeScore={changeScore}
          />
        </div>
      </div>

      <button
        type="button"
        className="btn btn-primary"
        onClick={onSave}
        disabled={!canSave}
      >
        Save
      </button>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </div>
  );
}

PasswordReset.propTypes = {};

export default PasswordReset;
