
import React, { useState, useEffect } from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import api from '../connection/api'
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Spinner from '../componets/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import lap from '../Assets/fwdartwork/Iap_Weblogos_Wordmark_Logo_Full_Color_RGB_500px@72ppi.png';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jwt-decode';

import './Header.css';




import {
  useQuery,
  gql
} from "@apollo/client";

// const GQL = gql`
//    {
//     apps {
//       id
//       name
//       sort
//       link
//       color
//     }
//     me{
//          id
//          company {
//              id
//              name
//         }
//       }
//   }`;


//  export default compose(
//     graphql(queries.getSubjects, {
//        name: "APPS_GQL"
//     }),
//     graphql(queries.getApps, {
//        name: "COMPANY_GQL"
//     }),

const Header = (props) => {
  const { customAuth } = props;
  const history = useHistory();

  const [companies, setCompanies] = useState([]);
  const [userCompany, setUserCompany] = useState({});
  const [user, setUser] = useState({});

  const Logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("companyid")
    setCompanies([])
    setUserCompany({})
    props.history.push("/")
    // window.location.reload(false);
  }
  const [apps, setApps] = useState([])
  const [IsSubscribed, setIsSubscribed] = useState([])
  const [IsBilling, setIsBilling] = useState([])
  useEffect(() => {
    if (localStorage.getItem('token') != null) {

      setUser(jwt(localStorage.getItem('token')));
    }

    getApps();
    getcompany();
    getcompanyByToken();
  }, [props.customAuth.isUserAuthenticated()])

  const handleError = (error) => {
    if (error.message.includes('Auth Check: No User')) {
      //No Error message
    } else if (error.message) {
      toast.error(error.message);
    } else if (error.networkError) {
      toast.error('A network error has occured');
      console.error('Error', error.networkError.result.errors);
    } else {
      toast.error('An unknown error has occured');
    }
  }

  const getcompanyByToken = async () => {

    fetch(api.url() + '/company/getCompanyBytoken', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        setUserCompany(data);
      }).catch(error => {

      });
  }

  const getcompany = async () => {

    fetch(api.url() + '/company', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(response => {
        return response.json()
      })
      .then(data => {

        setCompanies(data);


      }).catch(error => {

      });
  }

  const getApps = async () => {

    fetch(api.url() + '/company/getCompanyBytoken', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(company => {
       if(company.isBlocked==0)
       {
        fetch(api.url() + '/app', {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        })
          .then(response => {
            return response.json()
          })
          .then(data => {
           setApps(data)
            
    
    
          }).catch(error => {
    
          });
       }
     
      })
      .catch(error => {
        // handleError(error);
      });

   


  }



  const handleNameChange = (event) => {
    localStorage.setItem("companyid", event.target.value);

    fetch(api.url() + '/company/changeCompany2?id=' + event.target.value, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        // setCompanies(data);

        localStorage.setItem("token", data.token);
        window.location.reload();
      }).catch(error => {

      });
  };

  const handleNav = (event) => {

    fetch(api.url() + '/company/checkSubscription', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(resp => {
        setIsSubscribed(resp)

        fetch(api.url() + '/company/checkBilling', {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }).then(response => response.json())
          .then(data => {
            setIsBilling(data)
            event.preventDefault();
            let newPath = props.location.pathname.substring(0, props.location.pathname.substring(1).lastIndexOf('/') + 1);
            let billingpath = newPath + '/billing';
            if(newPath + '/billing' == billingpath && !IsSubscribed){
              toast.error("Please Choose subscription first");
              history.push('/app/manage/billing');
            }
            else if (newPath + '/billing' == billingpath && !data || !IsSubscribed) {
              toast.error("Please update Payment method first");
              history.push('/app/manage/billing');
            }
            else if (newPath == '') {
              return;

            }
            else if (newPath.includes('edit')) {
              newPath = newPath.substring(0, newPath.substring(1).lastIndexOf('/') + 1);
              history.push(newPath);
            }
            else {
              history.push(newPath);
            }



          })
          .catch(error => {
            handleError(error);

          });

      })
      .catch(error => {
        handleError(error);

      });



  }

  return (
    
    <Row>
      <Navbar fixed="top" variant="dark" style={{ backgroundColor: 'white' }}>
        {/* <Navbar.Brand onClick={handleNav}>Payroll Universe</Navbar.Brand> */}
        <Navbar.Brand onClick={handleNav}><img src={lap} style={{width:'150px', height:'40px'}}></img></Navbar.Brand>
        {<>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <div style={{ textAlign: "right", width: "100%", marginRight: "10px" }}>
            {user?.payrolluniverse?.isSuperAdmin ?
              <select className="headerDropdown" value={userCompany.companyId} onChange={handleNameChange}>
                {companies.map((company) =>
                  <option key={company.companyId} value={company.companyId}>{company.name}</option>
                )}
              </select>
              :
              <p style={{ color: '#050555', marginBottom: 0 }}>{userCompany.name}</p>
            }
          </div>
          {(props.customAuth.isUserAuthenticated() && !props.location.pathname.includes('reset')) &&
            <>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="justify-content-end dropdown-menu-lg-start" style={{ width: "97%" }}>

                  <NavDropdown title="Menu Options"
                    id="basic-nav-dropdown" style={{ marginRight: "30px" }}>
                    {apps.map(app => (
                      <NavDropdown.Item key={app.Id}>
                        <Link to={'/app/' + app.Link.toLowerCase()} style={{ color: "black", textDecoration: "none" }}>
                          {app.Name}
                        </Link>
                      </NavDropdown.Item>
                    ))}
                    <hr />

                    <NavDropdown.Item href="#action/3.1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-sliders" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                      </svg>
                      &nbsp;Settings
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2" onClick={Logout}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                        <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                      </svg>
                      &nbsp;Logout
                    </NavDropdown.Item>

                  </NavDropdown>
                  {/* <Navbar><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-bell-fill" viewBox="0 0 16 16">
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
              </svg></Navbar> */}
                </Nav>

              </Navbar.Collapse>
            </>
          }
        </>}
      </Navbar>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </Row>
  )
}


const mapStateToProps = (state, ownProps) => ({
  customAuth: state.authProvider,
  ownProps
});

const mapDispatchToProps = {

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))