import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import BonusReview from '../componets/BonusReview';
import Spinner from '../componets/Spinner';
import { formatter } from '../utils/currency';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../connection/api'
import CVSUpload from '../componets/CVSUpload'

function Bonuslist({ history, match }) {
    const [showReview, setShowReview] = useState();
    const [checkDate, setCheckDate] = useState([]);
    const [completed, setCompleted] = useState(false);
    // const [employees, setEmployees] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [total, setTotal] = useState("0.00");
    const [showUpload, setShowUpload] = useState(false);
    const [date, setDate] = useState([]);
    const [codes, setCodes] = useState([]);
    const [paymentrequest, setPaymentrequest] = useState([]);
    const [deptEmployees, setDeptEmployees] = useState(
        []
    );
    const [selectedFile, setSelectedFile] = useState(null);

    const [code, setCode] = useState([]);
    const [payrequest, setPayrequest] = useState(
        [
        //    { departmentId: '', employeeName: '', employeeId: '', dateId: '', amount: '', codeId: '', codeLabel: '' }
        ]

    );

    const [refresh,setRegresh] = useState(true);

    const [codeArray, setCodeArray] = useState([]);
    const [department, setDepartment] = useState([]);
    const [amount, setAmountArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }
    useEffect(() => {
        getDepartment();
        getDate();
        // getDeptemployee();
        getCode();
    }, []);
    useEffect(() => {},[refresh]);


    const getDepartment = async () => {

        fetch(api.url() + '/department/getdepartmentByrequester', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then(response => response.json())
            .then(data => {
                setSelectedGroup(data)
                setIsLoading(false)
            })
            .catch(error => {
                handleError(error);

            });

    }

    const getDate = async () => {

        fetch(api.url() + '/checkdate/getcheckdate', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then(response => response.json())
            .then(data => {
                setDate(data)

            })
            .catch(error => {
                handleError(error);

            });

    }

    const getCode = async () => {

        fetch(api.url() + '/code', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then(response => response.json())
            .then(data => {
                setCodes(data)

            })
            .catch(error => {
                handleError(error);

            });

    }

    const calculateTotal = (list) => {



        // setTotal(list.reduce((a, b) => a + (b.amount ? b.amount : 0), 0).toFixed(2));
        // //Add code to calulate code totals here.
        // //Reset the code totals
        // const newCodeTotal = {};
        // //Loop through the list
        // for (let index = 0; index < list.length; index++) {
        //     const element = list[index];
        //     if (!element.code) continue;
        //     //Determine if we already have the code in the code totals
        //     if (!newCodeTotal[element.code.id]) {
        //         //If not add as new
        //         newCodeTotal[element.code.id] = {
        //             code: element.code,
        //             total: 0
        //         };
        //     }
        //     newCodeTotal[element.code.id] = {
        //         ...newCodeTotal[element.code.id],
        //         total: newCodeTotal[element.code.id].total + element.amount
        //     }
        //     //If yes add to total
        // };
        // //End Loop
        // //Set State
        // setCodeTotal(Object.values(newCodeTotal));
    }

    const handleChange = (event, employeeid) => {


        const test = payrequest.find((obj) => obj.employeeId == employeeid)
        let ind = payrequest.findIndex(obj => obj.employeeId == employeeid)
        let tempObj = { ...test, amount: event.target.value }
        let tempPayreq = payrequest;
        tempPayreq[ind] = tempObj;
        setPayrequest(tempPayreq)

        let tempTotal = 0;
        {
            tempPayreq.map((pay, index) => {
                if (pay.amount != undefined) {
                    tempTotal += parseInt(pay.amount)
                }
                setTotal(tempTotal)

            })
        }
    }

    const handleCodeChange = (event, employeeid) => {

        const test = payrequest.find((obj) => obj.employeeId == employeeid)

        const codefind = codes.find((obj) => obj.codesId == event.target.value)
        let ind = payrequest.findIndex(obj => obj.employeeId == employeeid)
        let tempObj = { ...test, codeId: codefind.codesId, codeLabel: codefind.label }

        let tempPayreq = payrequest;
        tempPayreq[ind] = tempObj;
        setPayrequest(tempPayreq)

        let pay = payrequest.filter(obj => obj.amount !== undefined && obj.codeId !== undefined)
        setPaymentrequest(pay)
      
    }

    const pasteall = (id) => {
        let eidx = deptEmployees.findIndex((obj) => obj.employeeId == id);
        //let emp = deptEmployees.find((obj) => obj.employeeId == id);
        const srcPayRequest = payrequest.find((obj) => obj.employeeId == id);
         deptEmployees.filter((o,i)=> i>eidx ).map(emp=>{
            
            const test = payrequest.find((obj) => obj.employeeId == emp.employeeId);
            let ind = payrequest.findIndex(obj => obj.employeeId == emp.employeeId);
            let tempObj = { ...test, codeId:srcPayRequest.codeId , codeLabel: srcPayRequest.codeLabel,amount:srcPayRequest.amount }

            let tempPayreq = payrequest;
            tempPayreq[ind] = tempObj;
            setPayrequest(tempPayreq);

            let pay = payrequest.filter(obj => obj.amount !== undefined && obj.codeId !== undefined)
            setPaymentrequest(pay);
            let tempTotal = 0;
        {
            tempPayreq.map((pay, index) => {
                if (pay.amount != undefined) {
                    tempTotal += parseInt(pay.amount)
                }
                setTotal(tempTotal)

            })
            }

            setRegresh(!refresh);
         });
        
        
        // const { amount, code } = deptEmployees.find((o, i) => o.id === id)
        // const newState = [...deptEmployees];

        // for (let index = 0; index < newState.length; index++) {
        //     const element = newState[index];
        //     newState[index] = {
        //         ...element,
        //         amount,
        //         code
        //     }

        // }
        // setDeptEmployees([...newState]);
        //calculateTotal(newState);
    };
    //look here



    const groupFind = (event) => {
        setIsLoading(true)
        const groupId = event.target.value;
        fetch(api.url() + '/department/getdeptempById?id=' + groupId, {
            method: 'GET',

        }).then(response => response.json())
            .then(data => {
                setDeptEmployees(data)
                setPayrequest(data)
                console.log(data);
            })
            .catch(error => {
                handleError(error);

            });
        const department = selectedGroup.filter(obj => obj.id == event.target.value);
        
        setDepartment(department)
        setIsLoading(false)

    }

    const onDateChange = (event) => {



        const getdate = date.filter(obj => obj.checkDateId == event.target.value)

        setCheckDate(getdate)


    }

    const review = () => {

        setShowReview(true);
    }

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setShowUpload(true);
    };

    const submit = () => {
        setIsLoading(true)
        let data = "";
        let totalamt = parseFloat(total)
        data = {
            "totalamount": totalamt,
            "status": "PENDING",
            "checkDate": {
                "checkDateId": checkDate[0].checkDateId
            },
            "department": {
                "departmentId": paymentrequest[0].departmentId
            },
            "downloaded": false
        }
        fetch(api.url() + '/paymentrequest/add', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: new Headers({
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }),
        }).then(response => response.json())
            .then(data => {

                for (let i = 0; i < paymentrequest.length; i++) {
                    const payrequestdetail =
                    {
                        "amount": paymentrequest[i].amount,
                        "code": {
                            "codesId": paymentrequest[i].codeId
                        },
                        "employee": {
                            "employeeId": paymentrequest[i].employeeId
                        },
                        "paymentRequest": {
                            "paymentRequestId": data.paymentRequestId

                        }

                    }
                    fetch(api.url() + '/paymentrequestdetails/add', {
                        method: 'POST',
                        body: JSON.stringify(payrequestdetail),
                        headers: new Headers({
                            'Content-type': 'application/json; charset=UTF-8',
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }),
                    }).then(response => response.json())
                        .then(data => {
                        })
                        .catch(error => {
                            // Handle any errorsP
                            console.error(error);
                        });



                }
                setCompleted(true)
                setIsLoading(false);
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

        const empList = deptEmployees.map(employee => {
            // return {
            //     employeeId: employee.id,
            //     amount: employee.amount,
            //     codeId: employee.code.id
            // }

        });

        // addRequest({
        //     variables: {
        //         input: {
        //             groupId: selectedGroup.id,
        //             lineitems: [...empList],
        //             status: 'PENDING',
        //             checkDateId: checkDate.id
        //         }
        //     }
        // });
        // setCompleted(true);
    }
    const back = () => {
        setShowReview(false);
    }
    const changeAll = () => {
        setDepartment([])
        setDate([])
        setPayrequest([])

        let newPath = match.path.substring(0, match.path.substring(1).lastIndexOf('/') + 1);
        if (newPath.includes('paymentrequest')) {
            //removes the ID and edit
            newPath = newPath.substring(0, newPath.lastIndexOf('/'));
        }
        history.push(newPath);
        // setShowReview(undefined);
        // setCompleted(false);
        // setEmployees([]);
        // setSelectedGroup(undefined);
        // setTotal("0.00");
        // setShowUpload(false);

    }





    if (completed) {
        return <div style={{ width: '80%' }}>
            <div style={{ textAlign: "center" }}>
                <strong>Payment Requests have been submitted for review.</strong>
            </div>
            <BonusReview employees={paymentrequest} total={total} ></BonusReview>
            <button type="button" className="btn btn-primary" onClick={changeAll} style={{ margin: "5px" }}>Start New Request</button>
        </div>
    }

    if (showReview) {
        return <div style={{ width: '80%' }}>
            <BonusReview employees={paymentrequest} total={total} ></BonusReview>
            <Container>
                <Row>

                    <div style={{ textAlign: "right" }}>
                        <button type="button" className="btn btn-primary" onClick={back} style={{ margin: "5px" }}>Back</button>
                        <button type="button" className="btn btn-primary" onClick={submit}>Submit for Approval</button>
                    </div>
                </Row>
            </Container>
        </div>
    }


    return (
        <>{
            isLoading ? <Spinner />
                :
                <Container>
                    <Row>
                        <div className="col-md-12">
                            <label for="group">Department:</label>
                            <select onChange={groupFind} name="group" id="group">
                                <option value="">--Select a Department--</option>
                                {selectedGroup.map(group => (
                                    <option key={group.departmentId} value={group.departmentId}>{group.name}</option>
                                ))}
                            </select>
                            <br />
                            <label for="checkDate">Payment Date:</label>
                            <select onChange={onDateChange} name="checkDate" id="checkDate">
                                <option value="">--Select a Date--</option>
                                {date?.map((date, index) => (
                                    <option key={date.Id} value={date.checkDateId}>{date.formattedDate}</option>
                                ))}
                            </select>
                            &nbsp;

                            <label htmlFor="contained-button-file">
                                <CVSUpload
                                    type="paymentRequest"
                                    refetchQL=""
                                    exampleFile="/csv/paymentRequest.csv"
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFile}
                                    setShowUpload={setShowUpload}
                                />
                            </label>

                            {showUpload &&
                                <input
                                    type="file"
                                    accept="text/csv"
                                    onChange={handleFileSelect}
                                />}

                            {selectedGroup && checkDate && <>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Employee ID</th>

                                            <th scope="col">Name</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Code</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {payrequest?.map((employee, index) => (
                                            <>
                                                {employee !== undefined &&
                                                    <tr key={employee.employeeId}>
                                                        <th scope="row">{employee.employeeNumber}</th>

                                                        <td>{employee.employeeName} </td>
                                                        <td className="text-right">

                                                            <input type="number" placeholder="Amount" data-id={employee.employeeId} min="0" step="0.01" id="customRange3" 
                                                            style={{ width: '100%' }} onChange={(event) => handleChange(event, employee.employeeId)} value={employee.amount}/>
                                                        </td>
                                                        <td>
                                                            <select data-id={employee.employeeId} onChange={(event) => handleCodeChange(event, employee.employeeId)} 
                                                            style={{ width: '100%' }} value={employee.codeId}>
                                                                <option value="">--Select a Code--</option>
                                                                {codes.map((code, index) => (
                                                                    <option key={code.codesId} value={code.codesId}>{code.label}</option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-plus" viewBox="0 0 16 16" onClick={() => pasteall(employee.employeeId)}>
                                                                <path fillRule="evenodd" d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z" />
                                                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                            </svg>
                                                        </td>

                                                    </tr>
                                                }
                                            </>
                                        ))}
                                        <tr className="total">
                                            <th scope="row"></th>
                                            <td className="totals text-right">Total</td>
                                            <td className="totals text-right">{formatter.format(total)}</td>
                                        </tr>
                                    </tbody>
                                </table>


                                <div className="col-12">
                                    <div style={{ textAlign: "right" }}>
                                        <button type="button" className="btn btn-primary" onClick={review}>Review</button>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </Row >
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </Container>
        }
        </>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
}

export default connect(
    mapStateToProps,
    mapDispatchToProps)
    (Bonuslist)
