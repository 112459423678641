import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Table from 'react-bootstrap/Table';
import Spinner from '../../componets/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../connection/api'

import {
  useQuery,
  gql
} from "@apollo/client";
import CVSUpload from '../../componets/CVSUpload';

// const GROUPS_GQL = gql`
//   query GetGroups{
//     groups {
//       id
//       name
//       employees {
//         id
//       }
//     }
//   }
// `;




export const List = (props) => {
  const [showUpload, setShowUpload] = useState(false);
  const [groups, setGroups] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);



  useEffect(() => {

    getDepartment();
    getDeptemployee();

  }, []);

  const getDepartment = async () => {
    fetch(api.url() + '/department/getdepartment', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(data => {
        setGroups(data)

      })
      .catch(error => {
        handleError(error);

      });

  }


  const handleError = (error) => {
    if (error.message) {
      toast.error(error.message);
    } else if (error.networkError) {
      toast.error('A network error has occured');
      console.error('Error', error.networkError.result.errors);
    } else {
      toast.error('An unknown error has occured');
    }
  }




  // const { loading, error } = useQuery(GROUPS_GQL,
  //   {
  //     onCompleted: (data) => {

  //       setGroups([...data.groups]);
  //     },
  //     onError: handleError,
  //   });

  // const uploadStatus = () => {
  //   setShowUpload(!showUpload);
  // }

  // if (!groups || loading) {
  //   return <Spinner></Spinner>
  // }
  // const deleteGroups = () => {
  //   debugger;
  //   setGroups(groups.filter(groups => groups.id !== ));

  // }

  const getDeptemployee = async () => {

    fetch(api.url() + '/department/departmentemployee', {
      method: 'GET'
    }).then(response => response.json())
      .then(data => {
        setEmployees(data)
        setIsLoading(false)
      })
      .catch(error => {
        handleError(error);

      });

   
  }


  return (
    <>{
      isLoading ? <Spinner />
        :
        <div>

          <Link to={`${props.match.url}/newdept`}>
            <button type="button" className="btn btn-primary">New Department</button>
          </Link>

          <label htmlFor="contained-button-file">
            <CVSUpload type="department" refetchQL="GetGroups"  exampleFile="/csv/department.csv"></CVSUpload>
          </label>

          {showUpload &&
            <input
              type="file"
              accept="text/csv"
            />}

          <Table striped bordered hover variant="light">
            <thead>
              <tr>
                <th>Edit</th>
                <th>Department</th>
                <th>Employee Count</th>
                
              </tr>
            </thead>
            <tbody>
              {groups.map(group => (
                <tr key={group.departmentId}>
                  <td>
                    <Link to={`${props.match.url}/editgroup/${group.departmentId}`}>
                      Edit
                    </Link>
                  </td>
                  <td>{group.name}</td>
                  <td>{group.employeeCount}</td>
                 
                  {/* <td>
                <svg onClick={() => { deleteGroups(groups.id) }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-x-square-fill" viewBox="0 0 16 16">
                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                </svg>
              </td> */}

                </tr>
              ))}


            </tbody>
          </Table>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />
        </div >
    }
    </>
  )
}