import {
  LOGIN,
} from "./types";

export const loginUser = ({ token }) => async (dispatch) => {
  dispatch({
    type: LOGIN,
    payload: { token },
  });
};
