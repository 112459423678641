import React, { useEffect, useState } from 'react'
import { Container, Button, Form, Card, Row, Col } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';

import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import './Landing.css'
import Planet from '../../Assets/fwdartwork/Planet-Only-Logo.png';
import Payquest from '../../Assets/fwdartwork/ItsAboutPayroll_Branding_Board_PayQuest_App.png';

import {
    gql,
    useMutation,
} from "@apollo/client";

const CONTACT_GQL = gql`
    mutation ContactUs($name: String!, $email: String, $phone: String) {
        contactUs(input: {
            name: $name
            email: $email
            phone: $phone
        })
    }`

const Landing = ({ customAuth }) => {
    const [state, setState] = useState({})
    const [authinticated, setAuthinticated] = useState(false)
    const [showContactUs, setShowContactUs] = useState('false')
 

    useEffect(() => {
        setAuthinticated(customAuth.isUserAuthenticated())
    }, [setAuthinticated, customAuth])

    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    const [contactUsMutation] = useMutation(CONTACT_GQL, {
        onCompleted: (data) => {
            setShowContactUs('completed');
        },
        onError: handleError,
    })

    const handleSubmit = (event) => {
        event.preventDefault();
        contactUsMutation({
            variables: state
        });
    }

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        })
    }

    const handleShowContactUs = () => {
        setShowContactUs('show');
    }

    if (authinticated) {
        return <Redirect to='/app' />
    }

    return (
        <>
        <div className="landing fade-in-image">

            <div style={{textAlign:'center'}} >
                <img src={Planet} style={{ marginBottom: '-90px', width: '25%',  height: '379Px'  }} />
            </div>
            <br/>
            <div style={{textAlign:'center'}}>
            <img src={Payquest} style={{ width:'555px' }} />
            </div>
            <Button variant="secondary" size="lg" href="login" className="btn-get-started">
                Login
            </Button>
            <Button variant="primary" size="lg" href="signup" className="btn-get-started">
                Get Started
            </Button><br />

            <Button variant="primary" size="lg" className="btn-get-started" onClick={handleShowContactUs}>
                Contact us for a demo
            </Button>
          

            {showContactUs === 'show' &&
                    <Row>
                        <Col className="center" style={{paddingTop:'16px'}}>
                            <Form noValidate onSubmit={handleSubmit}>
                                <div className="subtitle">Contact us for a demo</div>

                                <Form.Group className="mb-3" style={{width: '78%', paddingLeft: '330px'}} controlId="formName">
                                    {/* <Form.Label>Name</Form.Label> */}
                                    <Form.Control type="text" name="name" placeholder="Name" onChange={handleChange} required />

                                </Form.Group>
                                <Form.Group className="mb-3" style={{width: '78%', paddingLeft: '330px'}} controlId="formPhone">
                                    {/* <Form.Label>Phone</Form.Label> */}
                                    <Form.Control type="phone" name="phone" placeholder="Phone" onChange={handleChange} />
                                </Form.Group>

                                <Form.Group className="mb-3" style={{width: '78%', paddingLeft: '330px'}} controlId="formEmail">
                                    {/* <Form.Label>Email address</Form.Label> */}
                                    <Form.Control type="email" name="email" placeholder="Company email" onChange={handleChange} />
                                </Form.Group>

                                <Button variant="primary" type="submit">
                                    Request a Demo
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                
            }

            {showContactUs === 'completed' &&
                <Card>
                    <Row>
                        <Col className="center">
                            Thanks for reaching out. We will contact you soon.
                        </Col>
                    </Row>
                </Card>
            }

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />

        </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    customAuth: state.authProvider,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Landing)