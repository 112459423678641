import React from 'react'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    useQuery,
    gql,
} from "@apollo/client";
import Spinner from '../../componets/Spinner';

const USER_GQL = gql`
    query GetUser {
      users {
        id
        name
        email
        status
        companies {
              id
          }
       
      }
    }
  `;



export const User = (props) => {
    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }


    const { loading, error, data } = useQuery(USER_GQL, {
        onError: handleError,
    });

    if (error) {
        return <p>error.message</p>
    }
    if (!data || loading) {
        return <Spinner></Spinner>
    }

    const toSentencCase = (string) => {
        if (!string) return string;
        var rg = /(^\w{1}|\.\s+\w{1})/gi;

        return string.toLowerCase().replace(rg, function (toReplace) {
            return toReplace.toUpperCase();
        });
    }

    return (
        <div>
            <Link to={`${props.match.url}/new`}>
                <button type="button" className="btn btn-primary">New User</button>
            </Link>



            <table className="table">
                <thead className="thead-dark">
                    <tr>


                        <th scope="col">User</th>
                        <th scope="col">Email</th>
                        <th scope="col">Status</th>

                    </tr>
                </thead>
                <tbody>
                    {data.users.map((User, index) => (
                        <tr key={User.id}>

                            <td>
                                <Link to={`${props.match.url}/edit/${User.id}`}>
                                    {User.name}
                                </Link>
                            </td>
                            <td>{User.email}</td>
                            <td>{toSentencCase(User.status)}</td>
                            {/* <td>{User.company}</td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />
        </div>

    )

}