import { useState, useEffect } from 'react';
import Employees from '../../componets/manager/Employees';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../connection/api'
import Spinner from '../../componets/Spinner';

import {
  useQuery,
  useLazyQuery,
  gql,
  useMutation,
} from "@apollo/client";

const EMPLOYEES_GQL = gql`
  query GetEmployees {
    employees {
      id
      name
    }
    approvers {
        id
        name

    }
  }
`;

// const GROUP_GQL = gql`
//   query group($groupId: ID!) {
//     group(id: $groupId) {
//       id
//       name
//       employees {
//         id
//         name
//       },
//       approvers {
//         id
//         name
//       },
//       requesters {
//         id
//         name
//       }
//     }
//   }
// `;

const CREATE_GQL = gql`
  mutation($name: String!, $approvers: [String]!, $requesters: [String]!, $employees:[String]!) {
    createGroup(
      input: {
        name: $name
        approverIds: $approvers
        requesterIds: $requesters
        employeeIds: $employees
      }
    ) {
      id
    }
  }
`
const UPDATE_GQL = gql`
  mutation($id: ID!, $name: String!, $approvers: [String]!, $requesters: [String]!, $employees:[String]!) {
    updateGroup(
      input: {
        id: $id
        name: $name
        approverIds: $approvers
        requesterIds: $requesters
        employeeIds: $employees
      }
      ) {
        id
        name
        employees {
          id
        }
    }
  }
`

export const Edit = ({ history, match }) => {
  const { groupid } = useParams()

  const [state, setState] = useState({
    name: '',
    approvers: [],
    employees: [],
    requesters: [],
  });
  const [isLoading, setIsLoading] = useState(true);


  const handleError = (error) => {
    if (error.message) {
      toast.error(error.message);
    } else if (error.networkError) {
      toast.error('A network error has occured');
      console.error('Error', error.networkError.result.errors);
    } else {
      toast.error('An unknown error has occured');
    }
  }

  const [employees, setEmployees] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [requesters, setRequesters] = useState([]);
  const [disabled, setDisabled] = useState(true);


  // const [getGroup] = useLazyQuery(GROUP_GQL, {
  //   onCompleted: ({ group }) => {
  //     console.log(group)
  //     setState({
  //       name: group.name,
  //       approvers: group.approvers,
  //       employees: group.employees,
  //       requesters: group.requesters,
  //     });
  //   },
  //   onError: handleError,
  // });

  useEffect(() => {
    getEmployees();
    getRequesters();
    getApprovers();
    if (groupid) {
      getDepartmentById();
    }
  }, [groupid])

  const getEmployees = async () => {

    fetch(api.url() + '/employee', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(data => {
        setEmployees(data)
        setIsLoading(false)
      })
      .catch(error => {
        handleError(error);

      });

  }

  const getApprovers = async () => {

    fetch(api.url() + '/employee/getApproverByCompany', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(data => {
        setApprovers(data)

      })
      .catch(error => {
        handleError(error);

      });

  }

  const getRequesters = async () => {

    fetch(api.url() + '/employee/getRequesterByCompany', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(data => {
        setRequesters(data)

      })
      .catch(error => {
        handleError(error);

      });

  }

  const getDepartmentById = async () => {

    fetch(api.url() + '/department/getdeptByid?id=' + groupid, {
      method: 'GET'
    }).then(response => response.json())
      .then(data => {
        setState(data)

      })
      .catch(error => {
        handleError(error);

      });

  }
  // useQuery(EMPLOYEES_GQL,
  //   {
  //     onCompleted: (data) => {
  //       setEmployees(data.employees);
  //       setApprovers(data.approvers);
  //     },
  //     onError: handleError,
  //   });



  const [createGroup] = useMutation(CREATE_GQL, {
    onCompleted: (data) => {
      returnToGroups();
    },
    onError: handleError,
    update(cache, { data: { createGroup } }) {
      cache.modify({
        fields: {
          groups(existingGroups = []) {
            const newGroupRef = cache.writeFragment({
              data: createGroup,
              fragment: gql`
                fragment NewGroup on Group {
                  id
                  type
                }
              `
            });
            return [...existingGroups, newGroupRef];
          }
        }
      });
    }
  })
  const [updateGroup] = useMutation(UPDATE_GQL, {
    onCompleted: (data) => {
      returnToGroups();
    },
    onError: handleError,
  })

  const updateState = (event) => {
    const { name, value } = event.target;
    const newState = {
      ...state,
      [name]: value
    };
    setState(newState);

    const disabled = !newState.name || !newState.approvers || newState.approvers.length === 0 ||
      !newState.requesters || newState.requesters.length === 0 ||
      !newState.employees || newState.employees.length === 0;

    setDisabled(disabled);
  };


  const onSave = (event) => {
    setIsLoading(true)
    if (groupid) {
      updateDepartment()
    }
    else {
      saveDepartment()
    }
  };

  const saveDepartment = () => {
    let emparr = [];
    let approverarr = [];
    let requesterarr = []

    for (let i = 0; i < state.employees.length; i++) {
      let empid = state.employees[i].employeeId;

      emparr.push({
        "employee": {
          "employeeId": empid
        }
      });
    }
    for (let i = 0; i < state.approvers.length; i++) {
      let empid = state.approvers[i].employeeId;

      approverarr.push({
        "employee": {
          "employeeId": empid
        }
      });
    }

    for (let i = 0; i < state.requesters.length; i++) {
      let empid = state.requesters[i].employeeId;

      requesterarr.push({
        "employee": {
          "employeeId": empid
        }
      });
    }

    let variables = {
      "name": state.name,
      "departmentEmployee": emparr,
      "approver": approverarr,
      "requester":requesterarr
    };

    fetch(api.url() + '/department/save', {
      method: 'POST',
      body: JSON.stringify(variables),
      headers: new Headers({
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    })
      .then(response => response.text())
      .then(data => {
        toast.success(data)
        returnToGroups();
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });
  }

  const updateDepartment = () => {

    const departmentId = parseInt(groupid, 10);
    const payload = {
      departmentId: departmentId,
      name: state.name,
      departmentEmployee: state.employees.map((employee) => {
        return {
          employee: employee,
          department: {
            departmentId: departmentId
          }
        };
      }),
      approver: state.approvers.map((approver) => {
        return {
          employee: approver,
          department: {
            departmentId: departmentId
          }
        };
      }),
      requester: state.requesters.map((requester) => {
        return {
          employee: requester,
          department: {
            departmentId: departmentId
          }
        };
      }),
    };

    fetch(api.url() + `/department/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (response.ok) {
          toast.success("Employees and Approvers updated successfully.")
        

          returnToGroups();
        } else {
          toast.error('Failed to update Employees and Approvers.');
         
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };




  const returnToGroups = () => {
    let newPath = match.path.substring(0, match.path.substring(1).lastIndexOf('/') + 1);
    if (newPath.includes('edit')) {
      //removes the ID and edit
      newPath = newPath.substring(0, newPath.lastIndexOf('/'));
    }
    // const newPath = match.path.substring(0, match.path.substring(1).lastIndexOf('/') + 1);
    history.push(newPath);
  }
  return (
    <>{
      isLoading ? <Spinner />
        :
        <div style={{ width: '80%' }}>
          Deparment Name
          <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4"></div>
          <div className="col-12 col-md-4 text-center">
            <InputGroup className="mb-3">
              <FormControl name="name" aria-label="Name" value={state.name} onChange={updateState} aria-describedby="inputGroup-sizing-sm" />
            </InputGroup>
          </div>
          <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4">
          </div>
          <Employees name="approvers" title="Approvers" employees={approvers} selected={state.approvers} onChange={updateState} sortable={true} ></Employees>
          <Employees name="requesters" title="Requestors" employees={requesters} selected={state.requesters} onChange={updateState} ></Employees>
          <Employees name="employees" value={employees} title="Employees" employees={employees} selected={state.employees} onChange={updateState} ></Employees>
          <div>
            <button type="button" className="btn" onClick={returnToGroups}>Cancel</button>
            <button type="button" disabled={disabled} className="btn" style={{background:'#2B50E9'}} onClick={onSave}>Save</button>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />
        </div>
    }
    </>
  )
}
