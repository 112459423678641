import React from 'react'
import { useState, useEffect } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../connection/api'
import Spinner from '../../componets/Spinner';

import {
    useLazyQuery,
    gql,
    useMutation,
} from "@apollo/client";

// const COMPANY_GQL = gql`
//       query GetCompany($companyId: ID!) {
//         company(id: $companyId) {
//           id
//           name
//           code
//           status
//         }
//       }
//     `;

// const CREATE_GQL = gql`
//       mutation($name: String!, $code: String!) {
//         createCompany(
//           input: {
//             name: $name
//             code: $code

//           }
//         ) {
//           id
//           name
//           code
//           status
//         }
//       }
//     `

// const UPDATE_GQL = gql`
//   mutation($id: ID!, $name: String!, $code: String!, $status: CompanyStatus) {
//     updateCompany(
//       input: {
//         id: $id
//         name: $name
//         code: $code
//         status: $status
//       }
//     ) {
//       id
//       name
//       code
//       status
//     }
//   }
// `

export const ManagerEdit = ({ history, match }) => {
    const { companyid } = useParams()
    const [isLoading, setIsLoading] = useState(true);

    const [state, setState] = useState({
        code: '',
        name: '',
        status: 'ACTIVE'

    });

    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    // const [getCompany] = useLazyQuery(COMPANY_GQL, {
    //     onCompleted: ({ company }) => {

    //         setState({
    //             ...company
    //         });
    //     },
    //     onError: handleError
    // });

    const getcompanyById = async () => {

        fetch(api.url() + '/company/getCompanyById?id=' + companyid, {
            method: 'GET'
        }).then(response => response.json())
            .then(data => {
                setState(data)
                setIsLoading(false)
            })
            .catch(error => {
                handleError(error);

            });

    }


    useEffect(() => {
        if (companyid) {
            getcompanyById();
        } else {
            setIsLoading(false)
        }
    }, [companyid])

    // const [createCompany] = useMutation(CREATE_GQL, {
    //     onCompleted: (data) => {
    //         gotoNewUser(data.createCompany.id);
    //     },
    //     refetchQueries: [
    //         'GetCompanies' // Query name
    //     ],
    //     onError: handleError
    // })

    // const [updateCompany] = useMutation(UPDATE_GQL, {
    //     onCompleted: (data) => {
    //         returnToCompany();
    //     },
    //     onError: handleError
    // })

    const updateCompany = async () => {
        fetch(api.url() + '/company/update', {
            method: 'PUT',
            body: JSON.stringify(state),
            headers: new Headers({
                'Content-type': 'application/json; charset=UTF-8',
            }),
        }).then(returnToCompany())
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }

    const updateState = (event) => {
        const { name, value } = event.target;
        setState({
            ...state,
            [name]: value
        })
    }
    // const updateStatusState = (event) => {
    //     setState({
    //         ...state,
    //         status: event.target.value
    //     })
    // }

    const createCompany = async () => {
        fetch(api.url() + '/company/save', {
            method: 'POST',
            body: JSON.stringify(state),
            headers: new Headers({
                'Content-type': 'application/json; charset=UTF-8',
            }),
        })
            .then(returnToCompany())
            .catch(error => {
                // Handle any errors
                console.error(error);
            });

    }

    const onSave = () => {
        setIsLoading(true)
        if (companyid) {
            updateCompany();

        } else {
            createCompany()
        }
    }

    const returnToCompany = () => {
        let newPath = match.path.substring(0, match.path.substring(1).lastIndexOf('/') + 1);
        if (newPath.includes('newcompany') || newPath.includes('editcompany')) {
            //removes the ID and edit
            newPath = newPath.substring(0, newPath.lastIndexOf('/'));
        }
        history.replace(newPath);
        window.location.reload();
    }

    const gotoNewUser = (companyId) => {
        const newPath = `/app/manage/users/new?cid=${companyId}`;
        history.push(newPath);
    }

    return (
        <>{
            isLoading ? <Spinner />
                :
                <div style={{ width: '80%' }}>
                    {companyid && <>Edit </>}
                    {!companyid && <>New </>}
                    Company
                    <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4"></div>
                    <div className="col-12 col-md-4 text-center">
                        <InputGroup className="mb-3">
                            <FormControl name="code" placeholder="Code" aria-label="Code" value={state.code} onChange={updateState} aria-describedby="inputGroup-sizing-sm" />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <FormControl name="name" placeholder="Company" aria-label="Company" value={state.name} onChange={updateState} aria-describedby="inputGroup-sizing-sm" />
                        </InputGroup>
                        <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4">
                        </div>
                        {/* <div>
                    <select name="status" onChange={updateState} value={state.status}>
                        <option value="ACTIVE">Active</option>
                        <option value="INACTIVE">Inactive</option>
                    </select>
                </div> */}
                        <button type="button" className="btn" onClick={returnToCompany}>Cancel</button>
                        <button type="button" className="btn" style={{background:'#2B50E9'}} onClick={onSave}>Save</button>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </div>
        }
        </>
    )



}