import React from 'react'
import { useState, useEffect } from 'react';

import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import api from '../../connection/api'
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../componets/Spinner';

export const Edit = ({ history, match }) => {
    const { employeeid } = useParams()
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessageNumber, setErrorMessageNumber] = useState("");
    const [errorMessageFirstname, setErrorMessageFirstname] = useState("");
    const [errorMessageLastname, setErrorMessageLastname] = useState("");

    const [state, setState] = useState({
        employeeId: employeeid,
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        status: '',
        number: '',
        employeeRoles: []
    });

    const [selectedRoles, setSelectedRoles] = useState([]);

    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    useEffect(() => {
        if (employeeid) {
            getEmpById()
        } else {
            setIsLoading(false);
        }
    }, [employeeid])

    const getEmpById = async () => {

        fetch(api.url() + '/employee/getEmployeeById?id=' + employeeid, {
            method: 'GET'
        }).then(response => response.json())
            .then(data => {
                setState(data)
                let arr = [];
                data.employeeRoles.map((data) => arr.push(data.Roles.RolesId))
                setSelectedRoles(arr);
                setIsLoading(false);
            })
            .catch(error => {
                handleError(error);
            });
    }

    const createEmployee = async () => {
        setIsLoading(true);
        const newState = state;
        newState.employeeRoles = [];
        selectedRoles.map((role) => {
            newState.employeeRoles.push({
                "roles": { "rolesId": role }, "employee": {
                    employeeId: parseInt(employeeid)
                }
            });
        })
        fetch(api.url() + '/employee/add', {
            method: 'POST',
            body: JSON.stringify(state),
            headers: new Headers({
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }),
        }).then(response => response.text())
            .then(data => {
                if(data==1)
                {
                    toast.success("Employee Added");
                }
                else{
                    toast.error(data);
                }

                returnToEmployees();
            })
            .catch(error => {
                // Handle any errors
                handleError(error);
            });
    }

    const updateEmployee = async () => {
        setIsLoading(true);
        const newState = state;
        newState.employeeRoles = [];
        selectedRoles.map((role) => {
            newState.employeeRoles.push({
                "roles": { "rolesId": role }, "employee": {
                    employeeId: parseInt(employeeid)
                }
            });
        })

     
        fetch(api.url() + '/employee/update', {
            method: 'PUT',
            body: JSON.stringify(state),
            headers: new Headers({
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }),
        }).then(response => response.json())
            .then(data => {
                returnToEmployees();
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });


    }
    const updateState = (event) => {
        const { name, value } = event.target;
        setState({
            ...state,
            [name]: value,
        })
        setErrorMessageNumber("");
        setErrorMessageFirstname("");
        setErrorMessageLastname("")
    }

    const updateRoles = (role) => {
        let temp = [...selectedRoles];
        if (temp.includes(role)) {
            temp = temp.filter((item) => item !== role);
        } else {
            temp.push(role);
        }
        setSelectedRoles(temp);
    };


    const onSave = () => {
        if (state.number === "" && state.firstName === "" && state.lastName ==="") {
            setErrorMessageNumber("* Please enter employee number");
            setErrorMessageFirstname("* Please enter first name");
            setErrorMessageLastname("* Please enter last name");
        } else if (state.firstName === "") {
            setErrorMessageFirstname("* Please enter first name");
        } else if (state.lastName === "") {
            setErrorMessageLastname("* Please enter last name");
        } else if (state.number === "") {
            setErrorMessageNumber("* Please enter employee number");
        } 
        else{
            if (employeeid) {
                updateEmployee()
            } else {
            createEmployee();
        }
        }
    }


    const returnToEmployees = () => {
        let newPath = match.path.substring(0, match.path.substring(1).lastIndexOf('/') + 1);
        if (newPath.includes('edit')) {
            //removes the ID and edit
            newPath = newPath.substring(0, newPath.lastIndexOf('/'));
        }
        history.push(newPath);
    }

    return (
        <>
            {
                isLoading ? <Spinner />
                    :

                    <div style={{ width: '100%' }}>
                    <div className="subtitle">Edit Employee</div>
                        
                        <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4"></div>
                        <div className="col-12 col-md-4" style={{paddingTop: '60px', paddingLeft: '60px'}}>
                            <InputGroup className="mb-3">
                                <FormControl name="firstName" placeholder="First Name" aria-label="firstName" value={state.firstName} onChange={updateState} aria-describedby="inputGroup-sizing-sm" />
                            </InputGroup>
                            {errorMessageFirstname && <p style={{lineHeight:"12px",fontWeight:"500",color:"red"}}>{errorMessageFirstname}</p>}
                            <InputGroup className="mb-3">
                                <FormControl name="lastName" placeholder="Last Name" aria-label="lastName" value={state.lastName} onChange={updateState} aria-describedby="inputGroup-sizing-sm" />
                            </InputGroup>
                            {errorMessageLastname && <p style={{lineHeight:"12px",fontWeight:"500",color:"red"}}>{errorMessageLastname}</p>}
                            <InputGroup className="mb-3">
                                <FormControl name="email" placeholder="Email" aria-label="Email" value={state.email.toLowerCase()} onChange={updateState} aria-describedby="inputGroup-sizing-sm" />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <FormControl name="number" placeholder="Employee Number" aria-label="Employee Number" value={state.number} onChange={updateState} aria-describedby="inputGroup-sizing-sm"  required />    
                            </InputGroup>
                            {errorMessageNumber && <p style={{lineHeight:"12px",fontWeight:"500",color:"red"}}>{errorMessageNumber}</p>}
                            <InputGroup className="mb-3">
                                <FormControl name="title" placeholder="Title" aria-label="Title" value={state.title} onChange={updateState} aria-describedby="inputGroup-sizing-sm" />
                            </InputGroup>

                            <InputGroup className="mb-3">
                                <select name="status" onChange={updateState} value={state.status}>
                                    <option value="">Select Status</option>
                                    <option value="ACTIVE">Active</option>
                                    <option value="LEAVE">On Leave</option>
                                    <option value="TERMINATED">Terminated</option>
                                </select>
                            </InputGroup>

                            <div><b>Roles</b></div>
                            <div className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id="requester"
                                    name="requester"
                                    value="4"
                                    label="Requester"
                                    onChange={() => updateRoles(4)}
                                    checked={selectedRoles?.includes(4)}
                                />
                                <Form.Check
                                    type="checkbox"
                                    id="approver"
                                    name="approver"
                                    value="3"
                                    label="Approver"
                                    onChange={() => updateRoles(3)}
                                    checked={selectedRoles?.includes(3)}
                                />
                                <Form.Check
                                    type="checkbox"
                                    id="admin"
                                    name="admin"
                                    value="2"
                                    label="Admin"
                                    onChange={() => updateRoles(2)}
                                    checked={selectedRoles?.includes(2)}
                                />
                            </div>

                        </div>
                        <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4">
                        </div>
                        <div style={{ paddingLeft: '50px'}}>
                            <button type="button" className="btn" onClick={returnToEmployees}>Cancel</button>
                            <button type="button" className="btn" style={{background:'#2B50E9'}} onClick={onSave}>Save</button>
                        </div>
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable={false}
                            pauseOnHover
                        />
                    </div>
            }
        </>
    )
}

