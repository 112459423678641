import {
    LOGIN,
    LOGOUT,
    CLEAR_ERRORS,
    // SET_CURRENT_USERINFO,
} from "../actions/types";
import jwt from 'jsonwebtoken';


const initialState = {
    token: localStorage.getItem("token"),
    user: {},
    isUserAuthenticated: () => {
        const token = localStorage.getItem('token');
        let jwtDecoded = jwt.decode(token);
        if (!jwtDecoded) {
            return false;
        };
        const { exp } = jwtDecoded;
        if (Date.now() >= exp * 1000) {
            localStorage.removeItem("token");
            return false;
        }

        return true;
    },
    loading: true,
    error: null,
    hasUserToken: localStorage.getItem("token") ? true : false,
    logedInUserEmail: localStorage.getItem("email")
        ? localStorage.getItem("email")
        : "",
};

const AuthProvider = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            localStorage.setItem("token", action.payload.token);
            localStorage.setItem("companyid", action.payload.companyid);
            return {
                ...state,
                ...action.payload,
                loading: false,
                hasUserToken: true,
            };

        case LOGOUT:
            localStorage.removeItem("token");
            return {
                ...state,
                token: null,
                loading: true,
                user: null,
                error: action.payload,

            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        // case SET_CURRENT_USERINFO:
        // return {
        //     ...state,
        //     isUserAuthenticated: true,
        //     user: action.payload,
        // };
        default:
            return state;
    }
};
export default AuthProvider;