import React, { useState } from 'react'
import { useEffect } from 'react';
import AppCard from '../componets/AppCard';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Spinner from '../componets/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../connection/api'

const AppBoard = () => {
    const [apps, setApps] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    useEffect(() => {
        getApps();
    }, [])

    const getApps = async () => {
        fetch(api.url() + "/app", {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                setApps(

                    data.sort(
                        (a, b) => {
                            if (a.Sort > b.Sort) {
                                return 1;
                            } else if (a.Sort < b.Sort) {
                                return -1;
                            }
                            return 0;
                        }
                    )
                )
                setIsLoading(false);
            }).catch(error => {
                handleError()
            });


    }

    const getColor = (app) => {
        return app.Color;
    }

    return (
        <>{
            isLoading ? <Spinner />
                :
                <Container>
                    <Row>

                        {apps.map(app => (


                            <Col key={app.Id} className="col-12 col-md-4 col-lg-3">
                                <Link to={'/app/' + app.Link.toLowerCase()}>
                                    <AppCard title={app.Name} color={getColor(app)}></AppCard>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </Container >
        }
        </>
    )
}

export default AppBoard
