import { useState, useEffect, Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import api from '../connection/api'
import RequestApproval from './pendingRequest'
import { formatter } from '../utils/currency';
import Spinner from '../componets/Spinner';

const RequestReview = ({ requestData, buttonComponent }) => {
  const [requests, setRequests] = useState([]);
  const [employee, setEmployees] = useState([]);
  const [paydetails, setPaydetails] = useState([]);
  const [paymentRequestApproval, setpaymentRequestApproval] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    await getpaymentRequestApproval();
    // getEmployees();
    getPaymentReqDetails();
  }, [])

  const onReview = (requestId) => {
    const newRequests = [...requests];
    const index = newRequests.findIndex(request => request.Id === requestId);
    const getpaydet = paydetails.filter(obj => obj.paymentRequest.Id == newRequests[index].Id)
    newRequests[index] = {
      ...newRequests[index],
      open: !newRequests[index].open,
      paymentdetails: getpaydet
    };
    setRequests(newRequests);
  }

  const getPaymentReqDetails = async () => {
    fetch(api.url() + '/paymentrequestdetails/getpaymentrequestdetails', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(data => {
        setPaydetails(data)
        setIsLoading(false)
      })
      .catch(error => {
        // handleError(error);
      });
  }

  const getEmployees = async () => {
    fetch(api.url() + '/employee', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(data => {
        setEmployees(data)
      })
      .catch(error => {
      });
  }

  const getPayrequest = async (approval) => {
    await fetch(api.url() + '/paymentrequest/getRequestApproval', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(data => {
        setRequests(data);
        updatedPaymentRequest(data, approval);
      
      })
      .catch(error => {
      });
  }
  const getpaymentRequestApproval = async () => {
    await fetch(api.url() + '/paymentrequestapproval/getPaymentRequestApproval', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(data => {
        setpaymentRequestApproval(data)
        getPayrequest(data);
      })
      .catch(error => {
        // handleError(error);
      });
  }

  const updatedPaymentRequest = (data, approval) => {
    const updatedRequest = data.map((request) => {
      const matchingApproval = approval.find((approval) => approval.paymentRequest.Id === request.Id);
      if (matchingApproval) {
        return {
          ...request,
          approverStatus: matchingApproval.status
        };
      }
      return request;
    });
    
    setRequests(updatedRequest);
  }

  return (
    <>{
      isLoading ? <Spinner />
        :
        <Container>
          <Row>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Company</th>
                  <th scope="col">Status</th>
                  <th scope="col">Total</th>
                  <th scope="col">Reason</th>
                  <th scope="col">Check Date</th>
                  <th scope="col">Requestor</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {requests && requests.map((req, index) => (
                  <Fragment key={req.Id}>
                    <tr key={req.Id}>
                      <td>
                        {req.department.name}
                      </td>
                      <td>{req.status}</td>
                      <td>{formatter.format(req.totalamount)}</td>
                      <td>{req.reason}</td>
                      <td>{req.checkDate.formattedDate}</td>
                      <td>{req.requester.firstName}</td>
                      <td>
                        {!req.open && req.approverStatus === 'PENDING' && <button onClick={() => onReview(req.Id)} type="button" className="btn btn-warning">Pending Review</button>}
                        {!req.open && req.approverStatus !== 'PENDING' && <button onClick={() => onReview(req.Id)} type="button" className="btn btn-success">Review</button>}
                        {req.open && <button onClick={() => onReview(req.Id)} type="button" className="btn btn-primary">Close</button>}
                      </td>
                    </tr>
                    {req.open && <tr>
                      <td colSpan="10">
                        <RequestApproval request={req} showReject={true}></RequestApproval>

                        {buttonComponent && <>
                          {buttonComponent({ request: req, index })}
                        </>}
                      </td>
                    </tr>
                    }
                  </Fragment>
                ))}
              </tbody>
            </table>
          </Row>
        </Container>
    }
    </>
  )
}

export default RequestReview;