import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import api from '../connection/api';
import Modal from 'react-bootstrap/Modal';
import Spinner from './Spinner';

function TemplateCard({setdefault,template,companytemplate}) {
  
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      
  }, [])

  const toggleModal = () => {
    setShowModal(!showModal);
}

   
    
  


    return (
        <>
       
                    
        <div style={{display:"flex",marginTop:"0.5vw",overflowX:"auto",overflowY:"hidden",height:"40vh",borderRadius:"20px",border:"none"}}>
        <Card style={{ width:"30vw",height:"100%",objectFit:"cover",backgroundColor:"#eceff4" }}>
            <div className="card-image-container">
            <Card.Img className="card-image" style={{height:"12vw"}} variant="top" src={`data:image/jpeg;base64,${template.templateFile}`}  onClick={toggleModal}/>
            </div>
            <Card.Body>
              <Card.Title style={{marginLeft:"5px"}}>{template.templateName}</Card.Title>
              {companytemplate.templateId == template.templateId  ? 
              <Button variant="primary" style={{backgroundColor:"#001d65",border:"none",outline:"none",color:"white"}} onClick={() => setdefault(template.templateId)} disabled>Selected</Button>
              
              
              :<Button variant="primary" style={{backgroundColor:"#001d65",border:"none",outline:"none",color:"white"}} onClick={() => setdefault(template.templateId)} >Set as default</Button>
            }
              </Card.Body>
         </Card>

         <Modal show={showModal} onHide={toggleModal} centered  size="lg">
                <Modal.Header>
                    <Modal.Title>{template.templateName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={`data:image/jpeg;base64,${template.templateFile}`} alt={template.templateName} style={{ maxWidth: '100%', borderRadius: "20px" }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleModal}>Close</Button>
                </Modal.Footer>
            </Modal>

        </div>

      </>
    )
}

export default TemplateCard
