import React from 'react'
import { useState, useEffect } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../connection/api'
import Spinner from '../../componets/Spinner';

import {
    useLazyQuery,
    gql,
    useMutation,
} from "@apollo/client";


const UPDATE_GQL = gql`
  mutation($id: ID!, $date: Date!) {
    updatedate(
      input: {
        id: $id
        date: $date
      }
    ) {
        id
        date
        formattedDate
    }
  }
`

export const Edit = ({ history, match }) => {
    const { dateid } = useParams()
    const [isLoading, setIsLoading] = useState(false);

    const [state, setState] = useState({
        date: '',
    });

    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }


    const createCheckDate = async () => {
        setIsLoading(true)
        fetch(api.url() + '/checkdate/new', {
            method: 'POST',
            body: JSON.stringify(state),
            headers: new Headers({
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }),
        }).then(response => response.text())
        .then((data) => {
            if(data==1){
             toast.success("Date Added")
            }
            else{
             toast.error(data)
            }
            returnTodates(); })
           .catch(error => {
             // Handle any errors
             console.error(error);
           });

    }

    const [updatedate] = useMutation(UPDATE_GQL, {
        onCompleted: (data) => {
            returnTodates();
        },
        onError: handleError,
    })

    const updateState = (event) => {
        const { value } = event.target;

        setState({
            ...state,
            date: value
        })
    }

    const onSave = () => {
        if (dateid) {
            updatedate({
                variables: state
            })
        } else {
            createCheckDate();
        }
    }

    const returnTodates = () => {
        let newPath = match.path.substring(0, match.path.substring(1).lastIndexOf('/') + 1);
        if (newPath.includes('edit')) {
            //removes the ID and edit
            newPath = newPath.substring(0, newPath.lastIndexOf('/'));
        }
        history.push(newPath);

    }

    return (
        <>{
            isLoading ? <Spinner />
                :
                <div style={{ width: '80%' }}>
                    Edit date
                    <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4"></div>
                    <div className="col-12 col-md-4 text-center">
                        <InputGroup className="mb-3">
                            <FormControl name="Date" type="date" placeholder="date" value={state.date} onChange={updateState} aria-describedby="inputGroup-sizing-sm" />
                        </InputGroup>
                    </div>
                    <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4">
                    </div>
                    <div>
                        <button type="button" className="btn" onClick={returnTodates}>Cancel</button>
                        <button type="button" className="btn" style={{background:'#2B50E9'}} onClick={onSave}>Save</button>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </div>
        }
        </>
    )
}