// import dotenv from 'dotenv';
// dotenv.config();

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setContext } from '@apollo/client/link/context';

//GraphQL
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client'
//process.env.NODE_ENV === 'development' ?
let uri = '';
if (window.location.href.includes('localhost')) {
  uri = 'http://localhost:4000/graphql';
}

const httpLink = createUploadLink({
  uri
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  const companyid = localStorage.getItem('companyid')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      companyid
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  // onError: ({ graphQLErrors, networkError, operation, forward }) => {
  //   if (graphQLErrors) {
  //     for (let err of graphQLErrors) {
  //       switch (err.extensions.code) {
  //         // Apollo Server sets code to UNAUTHENTICATED
  //         // when an AuthenticationError is thrown in a resolver
  //         case 'UNAUTHENTICATED':

  //           // Modify the operation context with a new token
  //           const oldHeaders = operation.getContext().headers;
  //           operation.setContext({
  //             headers: {
  //               ...oldHeaders,
  //               // authorization: getNewToken(),
  //             },
  //           });
  //           // Retry the request, returning the new observable
  //           return forward(operation);
  //       }
  //     }
  //   }

  //   // To retry on network errors, we recommend the RetryLink
  //   // instead of the onError link. This just logs the error.
  //   if (networkError) {
  //     console.log(`[Network error]: ${networkError}`);
  //   }
  // }
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
