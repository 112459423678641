import { useState, Fragment, useEffect } from 'react';
import Spinner from '../../componets/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import RequestApproval from '../../componets/pendingRequest';
import { formatter } from '../../utils/currency';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../connection/api'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import close from '../../Assets/fwdartwork/close_payroll.svg';
import {
    useQuery,
    gql,
    useLazyQuery,
    useMutation,
} from "@apollo/client";

const CANCEL_GQL = gql`
    mutation CancelRequest($id: ID!) {
        cancelPayRequest(input: {
        id: $id
        }){
            id
            status
            total
            reason
                        checkDate {
                    id
                    date
                    formattedDate
            }
            lineitems {
                id
                amount
                reason
                status
                code {
                    id
                    label
                }
                employee {
                    id
                    name
                    number
                    email
                    title
                    status
                }
            }
        }
    }
`





const MyRequests = ({ history, match, buttonComponent }) => {
    const [paydetails, setPaydetails] = useState([]);
    const [requests, setRequests] = useState([]);
    const [open,setOpen] = useState();
    const [date, setData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    // const [employee, setEmployees] = useState([]);
    useEffect(async () => {
        getRequest();
        getDate();

        getPaymentReqDetails();
    }, [])
    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    // const { loading } = useQuery(PENDING_REQUESTS_GQL,
    //     {
    //         onCompleted: (data) => {
    //             console.log('Rq', data);
    //             setRequests(data.myRequests)

    //             setRequests([...data.myRequests].sort((a, b) => {
    //                 if (a.status === 'PENDING' && b.status !== 'PENDING') {
    //                     return -1
    //                 }
    //                 return 0;
    //             }))
    //         },
    //         onError: handleError,
    //     });



    const sendingNudge = (paymentrequest) => {

        let  data = {
            "totalamount": paymentrequest.totalamount,
            "paymentRequestId":paymentrequest.Id,       
            "checkDate": paymentrequest.checkDate ,
            "department": {
                "departmentId":paymentrequest.department.id
            }
        }

        fetch(api.url() + '/paymentrequest/nudge', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-type': 'application/json; charset=UTF-8'
            }
        }).then(response => response.json())
            .then(data => {
                
            })
            .catch(error => {
               

            });
    }

    const getPaymentReqDetails = async () => {

        fetch(api.url() + '/paymentrequestdetails/getpaymentrequestdetails', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then(response => response.json())
            .then(data => {
                setPaydetails(data)
                setIsLoading(false)
            })
            .catch(error => {
                // handleError(error);

            });

    }




    const updateRequest = (newRequest) => {
        const newRequests = [...requests];
        const index = newRequests.findIndex(request => request.id === newRequest.id);
        newRequests[index] = {
            ...newRequests[index],
            ...newRequest,
            open: false
        };
        setRequests(newRequests);
    }
    const [cancelPayRequest] = useMutation(CANCEL_GQL, {
        onCompleted: (data) => {
            updateRequest(data.cancelPayRequest);
        },
        onError: handleError,
    })

    const onCancel = (id) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Are You Sure?")) {
            setIsLoading(true)
            fetch(api.url() + '/paymentrequest/updateStatus?status=CANCELED&paymentRequestId=' + id
                , {
                    method: 'PUT',

                    headers: new Headers({
                        'Content-type': 'application/json; charset=UTF-8',
                    }),
                }).then(response => response.json())
                .then(data => {
                    window.location.reload(false);
                })
                .catch(error => {
                    // Handle any errors
                    handleError(error)
                });
        }
    }


    const buttonWrapper = ({ request, index, }) => (
        request.status === 'PENDING' && <>
            <button onClick={() => onCancel(request.id, index)} type="button" className="btn btn-danger">Cancel</button>
        </>
    )


    const getDate = async () => {

        fetch(api.url() + '/checkdate', {
            method: 'GET'
        }).then(response => response.json())
            .then(data => {
                setData(data)
            })
            .catch(error => {
                handleError(error);

            });

    }

    const getEmployees = async () => {

        fetch(api.url() + '/employee', {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }),
        }).then(response => response.json())
            .then(data => {
                // setEmployees(data)

            })
            .catch(error => {


            });

    }

    const getRequest = async () => {

        fetch(api.url() + '/paymentrequest/getpaymentrequest', {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }),
        }).then(response => response.json())
            .then(data => {
                console.log(data)
                setRequests(data)

            })
            .catch(error => {
                handleError(error);

            });


    }
    const onReview = (requestId) => {

        const newRequests = [...requests];
        const index = newRequests.findIndex(request => request.paymentrequest.Id === requestId);
        console.log(paydetails);
        const getpaydet = paydetails.filter(obj => obj.paymentRequest.Id == newRequests[index].paymentrequest.Id)

        newRequests[index] = {
            ...newRequests[index],
            open: !newRequests[index].open,
            paymentdetails: getpaydet

        };
        setRequests(newRequests);
    }

    const [approversData,setApproversdata]=useState();
    const showApprovers =  async (payid) => {
        let Approversdata ;
       await fetch(api.url() + '/paymentrequestapproval/getPaymentRequestApprovalByPaymentRequest?id='+payid, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }),
        }).then(response => response.json())
            .then(data => {
               
              Approversdata = data;
              setApproversdata(Approversdata);
            })
            .catch(error => {
                handleError(error);

            });

            let msg = "";
            for (let i = 0; i < Approversdata.length; i++) {
            msg += Approversdata[i].employee.firstName + " " +  Approversdata[i].status+"\n";
        }
        if(Approversdata.length!=0){
            setOpen(!open);
        }
    }


    const showPendingApprovers =  async (payid) => {
        let PendingApproversdata ;
       await fetch(api.url() + '/paymentrequestapproval/getPendingPaymentRequestApproval?id='+payid, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }),
        }).then(response => response.json())
            .then(data => {
               
                PendingApproversdata = data;
                setApproversdata(PendingApproversdata);
            })
            .catch(error => {
                handleError(error);

            });

            let msg = "";
            for (let i = 0; i < PendingApproversdata.length; i++) {
            msg += PendingApproversdata[i].employee.firstName+ PendingApproversdata[i].status+"\n";
            
                }
        if(PendingApproversdata.length!=0){
            setOpen(!open);
        }  
    }

    // document.getElementById('change').onclick = changeColor;

    // function changeColor() {
    //     document.body.style.color = "red";
    //     return false;
    // }



    // if (!requests || loading) return <Spinner></Spinner>
    return (
        <>{
            isLoading ? <Spinner />
                :
                <Container>
                    <Row>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Company</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Reason</th>
                                    <th scope="col">Check Date</th>
                                    {/* <th scope="col">Next Approver</th> */}
                                    <th scope="col">Approver Count</th>
                                    <th scope="col">Pending Approvers</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>

                                </tr>
                            </thead>
                            <tbody>
                                {requests && requests.map((request, index, lineitem,) => (
                                    <Fragment key={request.paymentrequest.Id}>
                                        <tr key={request.paymentrequest.Id}>
                                            <td>
                                                {request.paymentrequest.department.name}
                                            </td>
                                            <td>{request.paymentrequest.status}</td>
                                            <td>{formatter.format(request.paymentrequest.totalamount)}</td>
                                            <td>{request.paymentrequest.reason}</td>
                                            <td>{request.paymentrequest.checkDate.formattedDate}</td>
                                            <td><a style={{ cursor: 'pointer' }} onClick={()=>showApprovers(request.paymentrequest.Id)}> {request.approverCount && request.approverCount}</a></td>
                                            <td> <a style={{ cursor: 'pointer' }} onClick={()=>showPendingApprovers(request.paymentrequest.Id)}> {request.pendingApprovalCount && request.pendingApprovalCount}</a> </td>

                                            <td>
                                                {!request.open && request.paymentrequest.status === 'PENDING' && <button onClick={() => sendingNudge(request.paymentrequest)} type="button" className="btn btn-outline-dark">Nudge</button>}
                                                {!request.open && request.paymentrequest.status === 'PENDING' && <button onClick={() => onReview(request.paymentrequest.Id)} type="button" className="btn btn-outline-dark">Show</button>}
                                                {!request.open && request.paymentrequest.status !== 'PENDING' && <button onClick={() => onReview(request.paymentrequest.Id)} type="button" className="btn btn-outline-success">Review</button>}
                                                {request.open && <button onClick={() => onReview(request.paymentrequest.Id)} type="button" className="btn btn-outline-primary">Close</button>}
                                            </td>
                                        </tr>
                                        {request.open && <tr>
                                            <td colSpan="10">
                                                <RequestApproval request={request} showCancel={true} setIsLoading={setIsLoading} />
                                                {!request.paymentrequest.status == 'APPROVED' &&
                                                    <button onClick={() => onCancel(request.paymentrequest.Id, index)} type="button" className="btn btn-outline-danger">cancel</button>
                                                }
                                                {buttonComponent && <>
                                                    {buttonComponent({ request, index })}
                                                </>}
                                            </td>
                                        </tr>
                                        }
                                    </Fragment>
                                ))}
                            </tbody>
                        </table>


                    </Row>

                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </Container >
        }
        <Modal show={open} onHide={()=>setOpen(!open)} centered={true} size='sm'>
        <Modal.Body>
            <div style={{display:"flex",justifyContent:"end",alignItems:"end"}}>
            <img style={{width:"12px",height:"12px",marginBottom:"10px"}} src={close} onClick={()=>setOpen(!open)}/>
            </div>
        <table>
        <thead>
          <tr>
            <th style={{width:"80%"}}>Approver Name</th>
            <th style={{width:"100%"}}>Status</th>
          </tr>
        </thead>
        <tbody>
          {approversData?.map((item, index) => (
            <tr key={index}>
              <td style={{width:"50%"}}>{item.employee.firstName}</td>
              <td style={{width:"100%"}}>{item.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </Modal.Body>
      <Modal.Footer>
          <Button variant="secondary" onClick={()=>setOpen(!open)}>Close</Button>
        </Modal.Footer>
        </Modal>
    
        </>
    )
}

export default MyRequests;