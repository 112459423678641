import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@material-ui/core/Button';
import Modal from 'react-modal';
import api from '../connection/api'
import Spinner from "./Spinner";

import { useState } from "react";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const CVSUpload = ({ mutation, type, refetchQL, exampleFile}) => {
  const [processing, setProcessing] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const handleError = (error) => {
    if (error.message) {
      toast.error(error.message);
    } else if (error.networkError) {
      toast.error('A network error has occured');
      console.error('Error', error.networkError.result.errors);
    } else {
      toast.error('An unknown error has occured');
    }
  }

  const addFile = async (file, type) => {


    let formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);

    fetch(api.url() + '/csv/save', {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
    })
      .then(response => response.json())
      .then(data => {
        toast.success(data);
        setProcessing(false);
        setShowModal(false);
      })
      .catch(error => {
        console.error(error);
        setProcessing(false);        
      });
  };

  const onChange = ({
    target: {
      validity,
      files: [file]
    }
  }) => {
    if (validity.valid) {
      if (file.name.endsWith('.xls') || file.name.endsWith('.xlsx') || file.name.endsWith('.csv')) {
        addFile(file, type);
        setProcessing(true);
      } else {
        toast.error('Please select a valid Excel file with .xls or .xlsx extension.');
      }
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  }
  const handleCloseModal = () => {
    setShowModal(false);
  }

  // if (processing) return <div>Your file is processing. You will recieve an email when the processing is complete.</div>;

  return (
    <>{
      processing ? <Spinner />
        :
        <React.Fragment>

          <label htmlFor="contained-button-file">
            <Button onClick={handleShowModal} variant="contained" color="primary" component="span">
              Upload
            </Button>
          </label>

          <Modal
            isOpen={showModal}
            contentLabel="File Upload"
            style={customStyles}
            onRequestClose={handleCloseModal}
          >
            <input type="file" required onChange={onChange} /><br />
            {exampleFile && <a href={exampleFile}>Sample File</a>}
            <div style={{ marginTop: '10px' }}>
              <Button onClick={handleCloseModal} variant="outlined" color="secondary" component="span">
                Cancel
              </Button>
            </div>
          </Modal>


          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />
        </React.Fragment>
    }
    </>
  );
};

export default CVSUpload
