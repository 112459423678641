import { gql } from "@apollo/client"
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CVSUpload from '../../componets/CVSUpload'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../connection/api'
import Spinner from "../../componets/Spinner";

import {
    useQuery,
} from "@apollo/client";

// const Date__gql = gql`
//     query Date {
//       checkDates {
//         id
//         date
//         formattedDate
//       }
//     }
//   `;

export const List = (props) => {
    const [showUpload, setShowUpload] = useState(false);
    const [checkDate, setCheckDate] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }


    // const { data } = useQuery(Date__gql, {
    //     onCompleted: (data) => {
    //         setCheckDate(data.checkDates[0]);
    //     },
    //     onError: handleError,
    // });

    useEffect(() => {

        getDate();

    }, [])

    const getDate = async () => {

        fetch(api.url() + '/checkdate/getcheckdate', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then(response => response.json())
            .then(data => {
                setCheckDate(data)
                setIsLoading(false)
            })
            .catch(error => {
                handleError(error);

            });

    }

    const uploadStatus = () => {
        setShowUpload(!showUpload);

    }

    return (
        <>{
            isLoading ? <Spinner />
                :
                <div>

                    <Link to={`${props.match.url}/newdate`}>
                        <button type="button" className="btn btn-primary">New Date</button>
                    </Link>
                    &nbsp;
                    <label htmlFor="contained-button-file">
                        <CVSUpload type="date" refetchQL="Date"  exampleFile="/csv/checkdate.csv"></CVSUpload>

                    </label>

                    {showUpload &&
                        <input

                            type="file"
                            accept="text/csv"
                           
                        />}

                    <table className="table">
                        <thead className="thead-light">
                            <tr>

                                <th scope="col">CheckDate</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td>
                                    {checkDate && checkDate.map((date, index) => (
                                        <div key={date.Id} value={index} style={{ paddingBottom: "10px" }}>
                                            {date.formattedDate}
                                        </div>
                                    ))}
                                </td>

                            </tr>


                        </tbody>
                    </table>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </div>
        }
        </>
    )



}