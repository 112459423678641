import React, { useEffect, useState, useRef } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { loginUser } from '../../actions/authActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../connection/api'
import ReCAPTCHA from 'react-google-recaptcha';
import { Navbar } from 'react-bootstrap';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import StoreIcon from '@material-ui/icons/Store';
import Payquest from '../../Assets/fwdartwork/ItsAboutPayroll_Branding_Board_PayQuest_App.png';

const Login = ({
    history, match,
    loginUser,
    customAuth
}) => {
    const [state, setState] = useState({ username: "", password: "" })

    const [employee, setEmployee] = useState([])
    const [error, setError] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isBilling, setIsBilling] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);
    const [captchaResponse, setCaptchaResponse] = useState(null);

    const [authinticated, setAuthinticated] = useState(false)

    useEffect(() => {
        setAuthinticated(customAuth.isUserAuthenticated())
    }, [setAuthinticated, customAuth])

    const captchaRef = useRef(null);

    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }
    const updateState = (event) => {
        const { name, value } = event.target;
        setState({
            ...state,
            [name]: value
        })
    }

    const [isVerified, setIsVerified] = useState(false);
    const handleRecaptchaChange = () => {
        setIsVerified(true);
    }

    const [open, setOpen] = React.useState(false);
    const [companyList, setCompanyList] = React.useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        selectCompany(value)
    };

    const onLogin = async () => {
        const { username, password } = state;
        const token = captchaRef.current.getValue()

        const payload = {
            username: username.toLowerCase(),
            password: password,
            token: token
        };

        fetch(api.url() + '/validate', {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: new Headers({
                'Content-type': 'application/json; charset=UTF-8',
            }),
        }).then(response => response.json())
            .then(async data => {
                console.log(data);
                var v = data['token'];
                localStorage.setItem('token', v);
                if (data.companies.length > 1) {
                    await setCompanyList(data.companies)
                    handleClickOpen()
                } else {
                    postValidate(data, payload)
                }
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
                toast.error("Could not login.");
            });
    }

    const selectCompany = async (company) => {
        const { username, password } = state;

        const payload = {
            username: username,
            password: password,
        };

        fetch(api.url() + '/company/changeCompany?id=' + company.companyId, {
            method: 'GET',
            headers: new Headers({
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }),
        }).then(response => response.json())
            .then(async data => {
                console.log(data);
                postValidate(data, payload)
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
                toast.error("Could not login.");
            });

    }

    const postValidate = async (data, payload) => {
        var v = data['token'];
        localStorage.setItem('token', v);
        setAuthinticated(true);
        if (data.status == 200) {
            await fetch(api.url() + '/employee/getEmployeeByEmail?email=' + payload.username, {
                method: 'GET',
                headers: new Headers({
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }),
            }).then(response => response.json())
                .then(async employee => {
                    setEmployee(employee);
                    if (employee.status != "ACTIVE") {
                        toast.error("Employee Status is not ACTIVE");
                        return
                    }
                    var v = data['token'];
                    localStorage.setItem('token', v);
                    setAuthinticated(true);
                    await fetch(api.url() + '/company/getCompanyBytoken', {
                        method: 'GET',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                        }),
                    }).then(response => response.json())
                        .then(company => {
                            if (company.isBlocked == 1) {
                                history.push('/blocked')
                            }
                            else {
                                fetch(api.url() + '/company/checkSubscription', {
                                    method: 'GET',
                                    headers: {
                                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                                    }
                                }).then(response => response.json())
                                    .then(async data => {
                                        fetch(api.url() + '/company/checkBilling', {
                                            method: 'GET',
                                            headers: {
                                                'Authorization': 'Bearer ' + localStorage.getItem('token')
                                            }
                                        }).then(response => response.json())
                                            .then(billingdata => {

                                                if (!data || !billingdata) {
                                                    history.push('/app/manage/billing');
                                                    window.location.reload();
                                                }                                               else {
                                                    history.push('/app');
                                                }
                                            })
                                    })
                                    .catch(error => {
                                        handleError(error);

                                    });
                            }
                        })
                })
        }
        else {
            toast.error("Mail or password is incorrect. Please try again.");
        }
    }

    // if (authinticated ) {
    //     return <Redirect to='/app' />
    // }

    // if (loading) {
    //     return <Spinner />
    // }

    return (

        <>
            <div>
                <Navbar bg="#fff" expand="md">
                    <div style={{ paddingLeft: '5px', textAlign: 'center', position: 'absolute', paddingBottom: '125px' }}>
                        <img src={Payquest} alt='planetLogo' style={{ width: '150px', height: '40px' }}></img>
                    </div>

                </Navbar>
            </div>

            <Container>
                <Row>
                    <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4"></div>
                    <div className="col-12 col-md-4 text-center">
                        {error && <div className="alert alert-danger"><strong>{error.message}</strong></div>}

                        <label for="email">Email address</label>
                        <input name="username" type="email" onChange={updateState} className="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email"></input>

                        <label for="password">Password</label>
                        <input name="password" type="password" onChange={updateState} className="form-control" id="password" placeholder="Password"></input>
                        <div style={{ margin: "5px", display: "flex", justifyContent: "center" }}>
                            <ReCAPTCHA
                                sitekey="6Lcz6UUpAAAAAOg8hdQp66x6OVSJOQwd7-A80hGz"
                                onChange={(value) => setCaptchaResponse(value)}
                                ref={captchaRef}
                            />
                        </div>

                        <div className="text-center">
                            <div className="form-check-center">
                            </div>
                            <button type="button" className="btn btn-outline-secondary" onClick={onLogin}>Login</button>
                        </div>

                        <div className="text-center">
                            <Link to='/reset'>
                                Forgot Password
                            </Link>

                        </div>
                    </div>
                    <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4"></div>
                </Row>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                />
                <SimpleDialog
                    open={open}
                    onClose={handleClose}
                    companyList={companyList}
                />
            </Container>
        </>
    )
}

const mapStateToProps = (state) => ({
    customAuth: state.authProvider,
})

const mapDispatchToProps = {
    loginUser
}

function SimpleDialog(props) {
    const { onClose, open, companyList } = props;

    const handleListItemClick = (value) => {
        console.log(value);
        onClose(value);
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Select company</DialogTitle>
            <DialogContent>This email is associated with multiple companies, select a company to proceed</DialogContent>
            <List sx={{ pt: 0 }}>
                {companyList.map((company) => (
                    <ListItem key={company.companyId} button onClick={() => handleListItemClick(company)}>
                        <ListItemAvatar>
                            <Avatar >
                                <StoreIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={company.name} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}


export default connect(mapStateToProps, mapDispatchToProps)(Login)
