import React from 'react'
import { useState, useEffect } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../connection/api'
import Spinner from '../../componets/Spinner';

import {
  useLazyQuery,
  gql,
  useMutation,
} from "@apollo/client";

const CODE_GQL = gql`
      query GetCodes($codeId: ID!) {
        code(id: $codeId) {
          id
          code
          description
        }
      }
    `;

// const CODES_GQL = gql`
// query codes {
//   codes {
//     id
//     label
//   }
// }
// `;

const CREATE_GQL = gql`
      mutation($code: String!, $description: String!) {
        createCode(
          input: {
            code: $code
            description: $description
          }
        ) {
          id
          code
          description
        }
      }
    `

const UPDATE_GQL = gql`
  mutation($id: ID!, $code: String!, $description: String!) {
    updateCode(
      input: {
        id: $id
        code: $code
        description: $description
      }
    ) {
      id
      code
          description
    }
  }
`

const CodeEdit = ({ history, match }) => {
  const { codeid } = useParams()
  const [isLoading, setIsLoading] = useState(true);

  const [state, setState] = useState({
    code: '',
    label: ''
  });

  const handleError = (error) => {
    if (error.message) {
      toast.error(error.message);
    } else if (error.networkError) {
      toast.error('A network error has occured');
      console.error('Error', error.networkError.result.errors);
    } else {
      toast.error('An unknown error has occured');
    }
  }

  // const [GetCodes] = useLazyQuery(CODE_GQL, {
  //     onCompleted: ({ code }) => {
  //         setState({
  //             ...code
  //         });
  //     },
  //     onError: handleError,
  // });

  useEffect(() => {

    if (codeid) {
      getCodebyId();
    } else {
      setIsLoading(false)

    }
  
  }, [codeid])

  // const [createcode] = useMutation(CREATE_GQL, {
  //     onCompleted: (data) => {
  //         returnTocodes();
  //     },
  //     onError: handleError,
  //     refetchQueries: [
  //         'codes' // Query name
  //     ],
  // })

  const getCodebyId = async () => {

    fetch(api.url() + '/code/getCode?id=' + codeid, {
      method: 'GET'
    }).then(response => response.json())
      .then(data => {
        setState(data)
        setIsLoading(false)
      })
      .catch(error => {
        handleError(error);
      });

  }


  const createCode = async () => {
    setIsLoading(true)
    fetch(api.url() + '/code/add', {
      method: 'POST',
      body: JSON.stringify(state),
      headers: new Headers({
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }),
    }).then(response => response.text())
    .then((data) => {
       if(data==1){
        toast.success("Code Added")
       }
       else{
        toast.error(data)
       }
       returnTocodes();
       })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });

  }

  const updateCode = async () => {
    setIsLoading(true)

    fetch(api.url() + '/code/update', {
      method: 'PUT',
      body: JSON.stringify(state),
      headers: new Headers({
        'Content-type': 'application/json; charset=UTF-8',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }),
    }).then(response => response.json())
      .then(data => {
        returnTocodes();
      })
      .catch(error => {
        // Handle any errors
        console.error(error);
      });

  }

  // const [updatecode] = useMutation(UPDATE_GQL, {
  //     onCompleted: (data) => {
  //         returnTocodes();
  //     },
  //     onError: handleError
  // })

  const updateState = (event) => {
    const { name, value } = event.target;

    setState({
      ...state,
      [name]: value
    })
  }

  const onSave = () => {
    if (codeid) {
      updateCode()
    } else {
      createCode();
    }
  }

  const returnTocodes = () => {
    let newPath = match.path.substring(0, match.path.substring(1).lastIndexOf('/') + 1);
    if (newPath.includes('edit')) {
      //removes the ID and edit
      newPath = newPath.substring(0, newPath.lastIndexOf('/'));
    }
    history.push(newPath);
    
  }

  return (
    <>{
      isLoading ? <Spinner />
        :
        <div style={{ width: '80%' }}>
          Edit Code
          <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4"></div>
          <div className="col-12 col-md-4 text-center">
            <InputGroup className="mb-3">
              <FormControl name="code" placeholder="Code" aria-label="Code" value={state.code} onChange={updateState} aria-describedby="inputGroup-sizing-sm" />
            </InputGroup>
            <InputGroup className="mb-3">
              <FormControl name="label" placeholder="Code Description" aria-label="Code" value={state.label} onChange={updateState} aria-describedby="inputGroup-sizing-sm" />
            </InputGroup>
          </div>
          <div className="col-sm-12 d-none d-sm-none d-md-block col-md-4">
          </div>
          <div>
            <button type="button" className="btn" onClick={returnTocodes}>Cancel</button>
            <button type="button" className="btn" style={{background:'#2B50E9'}} onClick={onSave}>Save</button>
          </div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />
        </div>
    }
    </>
  )
}

export default CodeEdit;