import {
    ADD_EMPLOYEE,
    REMOVE_EMPLOYEE,
    UPDATE_EMPLOYEE,
} from '../actions/types';
import faker from "faker";
faker.seed(1234);

let employees = [];
for (let index = 0; index < 20; index++) {
    employees.push(
        {
            id: index,
            name: faker.fake("{{name.lastName}}, {{name.firstName}}"),
            amount: undefined,
            code: '',
        },
    )
}

employees = employees.sort((a, b) => {
    if (a.name > b.name) {
        return 1;
    } else if (a.name < b.name) {
        return -1;
    } else {
        return 0;
    }
})

let initialState = {
    list: employees,
    total: 0,
}

const calculateTotal = (list) => {
    let totalAmount = 0;
    list.forEach(element => {
        totalAmount += element.amount ? element.amount : 0;
    });
    return totalAmount.toFixed(2);
}

const state = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_EMPLOYEE:
            const list = [...state.list];
            list.push(payload);
            return { ...state, list };
        case REMOVE_EMPLOYEE:
            return [...state]
        case UPDATE_EMPLOYEE:
            const newState = [...state.list];
            newState.find((o, i) => {
                if (o.id == payload.id) {
                    newState[i] = {
                        ...newState[i],
                        ...payload
                    };
                    return true;
                }
                return false;
            });
            const newTotal = calculateTotal(newState);
            return {
                ...state,
                list: newState,
                total: newTotal
            }
        default:
            return state
    }
}

export default state;