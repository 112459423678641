import React from 'react'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import CVSUpload from '../../componets/CVSUpload'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../componets/Spinner';
import api from '../../connection/api'

export const List = (props) => {
    const [employee, setEmployee] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showUpload, setShowUpload] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    useEffect(() => {
        getEmployees();
    }, [])

    const getEmployees = async () => {

        fetch(api.url() + '/employee', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then(response => response.json())
            .then(data => {
                setEmployee(data)
                setIsLoading(false);
            })
            .catch(error => {
                handleError(error);
            });
    }

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setShowUpload(true);
    };

    return (
            isLoading ? <Spinner />
                :
                <div>
                    <Link to={`${props.match.url}/new`}>
                        <button type="button" className="btn btn-primary">New Employee</button>
                    </Link>

                    <label htmlFor="contained-button-file">
                        <CVSUpload
                            type="employee"
                            refetchQL="GetEmployees"
                            exampleFile="/csv/employees.csv"
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            setShowUpload={setShowUpload}
                        />
                    </label>

                    {showUpload && (
                        <input type="file" accept="text/csv" onChange={handleFileSelect} />
                    )}


                    <table className="table">
                        <thead className="thead-dark">
                            <tr>

                                <th scope="col">Employee ID</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employee.map((employee, index) => (
                                <tr key={employee.employeeId}>
                                    <th scope="row">{employee.number}</th>
                                    <td>
                                        <Link to={`${props.match.url}/edit/${employee.employeeId}`}>
                                            {employee.firstName}
                                        </Link>
                                    </td>
                                    <td>{employee.email}</td>

                                    <td>{employee.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </div>
    )

}
