import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { formatter } from '../utils/currency';

function BonusReview({ employees, total }) {
  useEffect(() => {}, []);

  // Convert the total prop to a number
  const totalAmount = parseFloat(total);

  const codeLabelTotals = employees.reduce((totals, employee) => {
    if (!totals[employee.codeLabel]) {
      totals[employee.codeLabel] = 0;
    }
    totals[employee.codeLabel] += parseInt(employee.amount, 10); // Parse amount to an integer
    return totals;
  }, {});

  return (
    <Container>
      <Row>
        <div className="col-md-12">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Employee ID</th>
                <th scope="col">Name</th>
                <th scope="col" className="text-right">
                  Amount
                </th>
                <th scope="col">Code</th>
              </tr>
            </thead>

            <tbody>
              {employees.map((employee, index) => (
                <tr key={employee.employeeId}>
                  <td>{employee.employeeId}</td>
                  <td>{employee.employeeName}</td>
                  <td className="text-right">{formatter.format(employee.amount)}</td>
                  <td>{employee.codeLabel}</td>
                </tr>
              ))}

              {/* Display code label totals */}
              {Object.entries(codeLabelTotals).map(([codeLabel, codeLabelTotal], index) => (
                <tr className="total" key={index}>
                  <th scope="row"></th>
                  <td className="totals text-right">{codeLabel}</td>
                  <td className="totals text-right">{formatter.format(codeLabelTotal)}</td>
                </tr>
              ))}

              {/* Total */}
              <tr className="total">
                <th scope="row"></th>
                <td className="totals text-right">Total</td>
                <td className="totals text-right">{formatter.format(totalAmount)}</td>
              </tr>
            </tbody>
          </table>

          <div className="col-12">
            <div className="float-right"></div>
          </div>
        </div>
      </Row>
    </Container>
  );
}

export default BonusReview;
