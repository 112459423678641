import { Link } from 'react-router-dom';
import CVSUpload from '../../componets/CVSUpload'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../connection/api';
import { useState, useEffect } from 'react';
import Spinner from '../../componets/Spinner';

import {
    useQuery,
    gql,
} from "@apollo/client";

// const COMPANY_GQL = gql`
//     query GetMe {
//         me{
//             id
//             companies {
//                 id
//                 name
//                 code
//             }
//         }
//     }
//     `;

export const Manager = (props) => {

    const [company, setCompany] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleError = (error) => {
        if (error.message) {
            toast.error(error.message);
        } else if (error.networkError) {
            toast.error('A network error has occured');
            console.error('Error', error.networkError.result.errors);
        } else {
            toast.error('An unknown error has occured');
        }
    }

    useEffect(() => {
        getCompany();
    }, [])

    const getCompany = async () => {

        fetch(api.url() + '/company', {
            method: 'GET'
        }).then(response => response.json())
            .then(data => {
                setCompany(data)
                setIsLoading(false)
            })
            .catch(error => {
                handleError(error);

            });

    }

    // const { data } = useQuery(COMPANY_GQL, {
    //     onError: handleError,
    // });

    return (
        <>{
            isLoading ? <Spinner />
                :
                <div>
                    <Link to={`${props.match.url}/newcompany`}>
                        <button type="button" className="btn btn-primary">New Company</button>
                    </Link>
                    <label htmlFor="contained-button-file">
                        <CVSUpload type="company" refetchQL="GetMe"></CVSUpload>

                    </label>
                    <table className="table">
                        <thead>
                            <tr>

                                <th scope="col">Code</th>
                                <th scope="col">Company</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>

                            {company && company.map((company) => (
                                <tr key={company.companyId}>
                                    <th scope="row">{company.code}</th>
                                    <td>
                                        <Link to={`${props.match.url}/editcompany/${company.companyId}`}>
                                            {company.name}
                                        </Link>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                    />
                </div>
        }
        </>
    )



}