import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Spinner from '../../componets/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import api from '../../connection/api'
import 'react-toastify/dist/ReactToastify.css';
import {
  useQuery,
  useLazyQuery,
  gql,
  useMutation,
} from "@apollo/client";
import { useEffect } from 'react';
import { Button, Container, Row } from 'react-bootstrap';

// const COMPANY_GQL = gql`
//     query GetCompany {
//         company {
//             id
//             name
//             code
//             status
//             subscriptions {
//                 currentPeriodEnd
//             }
//             stats{
//                 admins,
//                 approvers,
//                 employees
//             }
//         }
//     }
// `;


export const Invoice = (props) => {
  const [state, setState] = useState({
    admins: {},
    approvers: {},
    employees: {},
    total: {},
  })
  const [subscriptions, setSubscriptions] = useState([]);
  const [customerSubscription, setCustomerSubscription] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {

    getSubscriptionList();
    getCustomerSubscription();
    checkIsBilling();
  }, [])

  const [isBilling, setIsBilling] = useState([])

  const handleError = (error) => {
    if (error.message) {
      toast.error(error.message);
    } else if (error.networkError) {
      toast.error('A network error has occured');
      console.error('Error', error.networkError.result.errors);
    } else {
      toast.error('An unknown error has occured');
    }
  }



  const cellStyle = {
    paddingRight: '30px'
  }
  const numberStyle = {
    paddingRight: '30px',
    textAlign: 'right'
  }

  const checkIsBilling = (error) => {

    fetch(api.url() + '/company/checkBilling', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(data => {
        setIsBilling(data)

      })
      .catch(error => {
        handleError(error);

      });
  }
  // if (!data || loading) {
  //     return <Spinner></Spinner>
  // }


  const handleUpdateClick = async () => { }

  const getSubscriptionList = async () => {

    await fetch(api.url() + '/subscription/getProducts', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(data => {
        setSubscriptions(data)
        getCustomerSubscription();
        setIsLoading(false)

      })
      .catch(error => {

      });
  }
  const getCustomerSubscription = async () => {

    await fetch(api.url() + '/subscription/getCustomerSubscription', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => response.json())
      .then(data => {
        setCustomerSubscription(data);
        setIsLoading(false)

      })
      .catch(error => {
        // handleError(error);
      });

  }
  const setdefault = (prodId) => {
    setIsLoading(true)
    if (customerSubscription.length!=0) {
      let customerSubsId;
     
      customerSubsId = customerSubscription.customerSubscriptionId;
      
      fetch(api.url() + '/subscription/updateCustomerSubscription?prodId=' + prodId + '&custSubsId=' + customerSubsId, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(response => response.text())
        .then(data => {
          if (data == 1) {
            toast.success("Subscription changed")
          }
          else {
            toast.error(data)
          }



          getCustomerSubscription();



        })
        .catch(error => {
          // handleError(error);
        });
    }
    else {
      fetch(api.url() + '/subscription/createCustomerSubscription?prodId=' + prodId, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      })
        .then(data => {

          getCustomerSubscription();




        })
        .catch(error => {
          // handleError(error);
        });
    }



  }

  return (
    <>
      {
        isLoading ? <Spinner />
          :
          <div>
            
            
            <h1>Billing Information</h1>
            {!isBilling && customerSubscription.stripeProductId && 
              <div style={{ marginTop: '20px' }}>
                <h5>Please Update Payment Method</h5>
              </div>
              
            }
             {!customerSubscription.stripeProductId && 
              <div style={{ marginTop: '20px' }}>
                <h5>Please Choose Subscription first</h5>
              </div>
              
            }
            
            {customerSubscription.stripeProductId &&
              <Link to={`${props.match.url}/payment`}>
                Update Payment Method
              </Link>
            }
            
            <br />


            <strong>Next Bill Date:</strong> {state.endDate}<br />
            

                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Name</th>
                      <th scope="col">UserCount</th>
                      <th scope="col">Amount</th>


                    </tr>
                  </thead>
                  <tbody>
                    {subscriptions && subscriptions.map((request, index, lineitem,) => (

                      <tr key={request.subscriptionsMasterId}>

                        <td>{request.subscriptionsMasterId}</td>
                        <td>{request.subscriptionName}</td>
                        <td>{request.userCount}</td>
                        <td>
                          ${request.amount}
                        </td>
                        <td>
                          {customerSubscription.stripeProductId == request.priceId ?
                            <Button variant="primary" style={{ backgroundColor: "#001d65", border: "none", outline: "none", color: "white" }} disabled>Selected</Button>


                            : <Button onClick={() => setdefault(request.priceId)} type="button" className="btn btn-primary">Choose </Button>
                          }

                        </td>
                      </tr>


                    ))}
                  </tbody>
                </table>



              

              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
              />
            


          </div>
      }
    </>
  )
}
